import React from "react"

import { v4 as uniqid } from "uuid"
import history from "./comp/History"
import get from "lodash/get"
import { logError } from "./services/logging.service"
import api from "./services/api.service"

import { getErrorMessage, getErrorStackMapped } from "./services/logging.service"

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.

        return {
            hasError: true,
            error,
        }
    }

    componentDidCatch(error) {
        getErrorStackMapped(error)
            .then((stack) => {
                this.setState({ stack })

                if (process.env.REACT_APP_DISABLE_LOGGING !== "true") {
                    return api.post("/log/mission-x", {
                        userAgent: get(window, "navigator.userAgent"),
                        url: get(window, "location.href"),
                        message: getErrorMessage(error),
                        error: stack,
                    })
                }
            })
            .catch((err) => {
                console.error(err)
            })

        this.props.app?.unBlock()

        if (this.props.context) {
            logError(this.props.context + " - " + error)
        } else {
            logError(error)
        }

        const errorId = uniqid()

        this.props.app?.showAppElements()

        history.push("/home?diagnostic=" + errorId)
    }

    render() {
        if (this.state.hasError) return <></>
        return this.props.children
    }
}
