import React, { useState, useEffect, useRef } from "react"
import cx from "classnames"
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi"
import { motion, AnimatePresence } from "framer-motion"
import BlurHandler from "./BlurHandler"
import ErrorBoundary from "../ErrorBoundary"

const TabBar = ({ index, tabs, onChange, className }) => {
    const tabContainer = useRef()
    const [widthAvailable, setWidthAvailable] = useState(0)

    const [menuIndex, setMenuIndex] = useState()
    const [showMenu, setShowMenu] = useState()

    useEffect(() => {
        let wNeeded = 0
        let cIndex = undefined

        tabContainer.current.childNodes.forEach((node, indx) => {
            wNeeded += node?.getBoundingClientRect().width || 0

            if (cIndex === undefined && wNeeded > widthAvailable) {
                cIndex = indx
            }
        })

        setShowMenu(null)
        setMenuIndex(cIndex)
    }, [tabs.length, widthAvailable])

    useEffect(() => {
        if (tabContainer.current) {
            const observer = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    setWidthAvailable(entry.contentRect.width)
                }
            })

            observer.observe(tabContainer.current)

            // Cleanup function
            return () => {
                observer.disconnect()
            }
        }
    }, [])

    return (
        <>
            <ul className={cx("x-tab-bar", className)} ref={tabContainer}>
                {tabs.map((tab, i) => {
                    return (
                        <li
                            key={(tab.id || i) + "-li"}
                            className={cx(tab.tabClassName, {
                                active: index === i,
                                hide: menuIndex !== undefined && i >= menuIndex,
                            })}
                            style={tab.style}
                            onClick={() => {
                                onChange(i)
                            }}
                        >
                            {tab.title}
                        </li>
                    )
                })}

                {menuIndex !== undefined && (
                    <li
                        className="x-tab-more"
                        onClick={(e) => {
                            e.persist()
                            setShowMenu(e)
                        }}
                    >
                        <PiDotsThreeOutlineVerticalFill />
                    </li>
                )}
            </ul>
            <BlurHandler
                onBlur={() => {
                    setShowMenu(null)
                }}
            >
                <ul
                    style={{ left: showMenu?.pageX, top: showMenu?.pageY }}
                    className={cx("x-tabs-more-menu", { open: Boolean(showMenu) })}
                >
                    {tabs.map((tab, i) => {
                        return (
                            <li
                                key={(tab.id || i) + "-li"}
                                className={cx(tab.tabClassName, {
                                    active: index === i,
                                    hide: i < menuIndex,
                                })}
                                style={tab.style}
                                onClick={() => {
                                    onChange(i)
                                    setShowMenu(null)
                                }}
                            >
                                {tab.title}
                            </li>
                        )
                    })}
                </ul>
            </BlurHandler>
        </>
    )
}

const Tabs = ({ activeTabIndex, onChange, data, className, cb, bodyClassName, returnArray }) => {
    const [activeTab, setActiveTab] = useState(activeTabIndex || 0)

    const urlParams = new URLSearchParams(window.location.search)
    const org = urlParams.get("org")

    const [myTabs, setMyTabs] = useState([])

    const cn = cx("dna-tabs-list", className)

    useEffect(() => {
        const tabs = data.map((tab, index) => ({
            title: <>{typeof tab.title === "string" ? tab.title : tab.title()}</>,
            getContent: () => <></>,
            tabClassName: cx("x-tab", tab.className),
            panelClassName: "x-tab-panel",
        }))
        setMyTabs(tabs)
    }, [data])

    useEffect(() => {
        if (activeTabIndex !== activeTab) setActiveTab(activeTabIndex || 0)
    }, [activeTabIndex, activeTab])

    const theTab = data[activeTab]

    const tabList = (
        <TabBar
            tabs={myTabs}
            index={activeTab}
            className={cn}
            onChange={(tabIndex) => {
                if (data[tabIndex].cb) {
                    return data[tabIndex].cb(tabIndex, data[tabIndex])
                } else {
                    setActiveTab(tabIndex)
                    if (onChange) onChange(tabIndex, data[tabIndex])
                }
            }}
        />
    )

    const tabId = theTab?.id

    let tabPanel

    if (org === "666b0afd5b40852cdca0991b") {
        tabPanel = theTab ? (
            <ErrorBoundary app={this} context={`tabs-${tabId}`}>
                {theTab?.notComponent ? React.createElement(theTab?.comp, theTab.params) : theTab?.comp}
            </ErrorBoundary>
        ) : (
            <></>
        )
    } else {
        tabPanel = theTab ? (
            <AnimatePresence>
                <motion.div
                    key={activeTab}
                    className={bodyClassName}
                    initial={{ opacity: 0, pointerEvents: "none" }}
                    animate={{ opacity: 1, pointerEvents: "all" }}
                    exit={{ opacity: 0, pointerEvents: "none" }}
                    transition={{ delay: 0.2, duration: 0.2 }}
                >
                    <ErrorBoundary app={this} context={`tabs-${tabId}`}>
                        {theTab?.notComponent ? React.createElement(theTab?.comp, theTab.params) : theTab?.comp}
                    </ErrorBoundary>
                </motion.div>
            </AnimatePresence>
        ) : (
            <></>
        )
    }

    if (returnArray) return [tabList, tabPanel]

    return (
        <>
            {tabList}
            {tabPanel}
        </>
    )
}

Tabs.displayName = "Tabs"
export default Tabs
