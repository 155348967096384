import React, { useState, useEffect, useCallback, useRef } from "react"
import { DepartmentFilter } from "../comp/orgFilters"
import { PiPushPinDuotone, PiClockCountdownDuotone } from "react-icons/pi"
import { Scrollbars } from "react-custom-scrollbars"
import SearchDropDown from "./SearchDropDown"
import { toZonedTime } from "date-fns-tz"
import { getPersonName, getRefId, sortAlpha, getObjectId, useDebouncedEffect } from "../utils/utils"
import { findAiEndDate, getTimeUtc, getStartHour } from "../utils/dates"
import { isOverdue, actionItemPermissions, getAiCode } from "../utils/actionItem"
import { getAllActions } from "../comp/MissionUtils"
import format from "date-fns/format"
import CheckBoxes from "../comp/CheckBoxes"
import Button from "./Button"
import Hive from "./Hive"
import flatMap from "lodash/flatMap"
import numeral from "numeral"
import uniqBy from "lodash/uniqBy"
import { isFriday } from "date-fns/isFriday"
import { isSameDay } from "date-fns/isSameDay"
import { eachDayOfInterval } from "date-fns/eachDayOfInterval"
import { endOfISOWeek } from "date-fns/endOfISOWeek"
import { nextFriday } from "date-fns/nextFriday"
import { isWeekend } from "date-fns/isWeekend"
import { addWeeks } from "date-fns/addWeeks"
import { startOfISOWeek } from "date-fns/startOfISOWeek"
import addDays from "date-fns/addDays"
import startOfDay from "date-fns/startOfDay"
import isSameISOWeek from "date-fns/isSameISOWeek"
import cx from "classnames"
import { addParam } from "./History"
import "ag-grid-enterprise"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/styles/ag-grid.css"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { LicenseManager } from "ag-grid-enterprise"
import "../scss/DnaAgGrid.scss"
import "./TaskMaster.scss"

import { AiFillCaretDown } from "react-icons/ai"

LicenseManager.setLicenseKey(
    "CompanyName=Mission-X LTD,LicensedApplication=Mission-X,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-033160,SupportServicesEnd=22_September_2023_[v2]_MTY5NTMzNzIwMDAwMA==e2181c44967b4c010344ad9c5f7af912"
)

const TaskMaster = ({ app, stateChange }) => {
    let presets = localStorage.getItem(`x-tm-filters-${app.state.person._id}`)

    if (presets) {
        try {
            presets = JSON.parse(presets)
        } catch (e) {
            presets = {}
        }
    }

    const gridRef = useRef()
    const peopleRef = useRef([])

    const missionRef = useRef(
        Object.fromEntries(app.state.missions.filter((m) => !m.isTemplate).map((item) => [item._id, item]))
    )
    const orgRef = useRef(Object.fromEntries(app.state.orgs.map((item) => [item._id, item])))
    const [totalTasks, setTotalTasks] = useState(0)
    const [sort, setSort] = useState("seen")
    const [columnDefs, setColumnDefs] = useState([])
    const [rowData, setRowData] = useState([])
    const [orgFilter, setOrgFilter] = useState(presets?.orgFilter)
    const [hideDone, setHideDone] = useState(presets?.hideDone || "all")
    const [missionFilter, setMissionFilter] = useState(presets?.missionFilter)
    const [showPinned, setShowPinned] = useState(presets?.showPinned)
    const [personFilter, setPersonFilter] = useState(app.state.person._id)
    const [people, setPeople] = useState([])
    const [statusFilter, setStatusFilter] = useState(presets?.statusFilter)
    const [departmentFilter, setDepartmentFilter] = useState(presets?.departmentFilter)

    const oneOrg = app.state.orgs.length === 1 ? app.state.orgs[0] : false

    //Grid
    const getRowId = useCallback((params) => {
        return params?.data._id
    }, [])

    const getRowHeight = useCallback((params) => {
        return 120
    }, [])

    const isExternalFilterPresent = useCallback(() => {
        return true
    }, [])

    const doesExternalFilterPass = useCallback(
        (node) => {
            let isStatusOk = !statusFilter || node.data.status === statusFilter.id
            const ai = node.data?.ai

            if (statusFilter && ai) {
                if (statusFilter.id === "not-planned") {
                    isStatusOk = !node.data.dueDate && !ai.week
                } else if (statusFilter && statusFilter.id === "overdue" && node.data?.isOverdue) {
                    isStatusOk = true
                } else if (statusFilter && statusFilter.id === "blocked" && node.data?.ai?.status === "blocked") {
                    isStatusOk = true
                } else if (statusFilter && statusFilter.id === "cr-open") {
                    isStatusOk =
                        node.data?.ai?.approvals?.find((a) => !a.cancelledOn && !a.approvedOn) && ai.status !== "done"
                } else if (statusFilter && statusFilter.id === "cr-approved") {
                    isStatusOk = node.data?.ai?.approvals?.find((a) => a.approvedOn)
                } else if (statusFilter.id === "due-today" && ai.status !== "done") {
                    isStatusOk = node.data.dueDate && isSameDay(node.data.dueDate, new Date())
                } else if (statusFilter.id === "due-tomorrow" && ai.status !== "done") {
                    isStatusOk = node.data.dueDate && isSameDay(node.data.dueDate, addDays(new Date(), 1))
                } else if (statusFilter.id === "due-this-week" && ai.status !== "done") {
                    isStatusOk = node.data.dueDate && isSameISOWeek(node.data.dueDate, new Date())
                }
            }

            let isDepOk = !departmentFilter || node.data?.mission?.departmentTags?.includes(departmentFilter?._id)

            let personOk = !personFilter || node.data?.ai?.people?.includes(personFilter)

            if (personFilter === "unassigned") {
                personOk = !node.data?.ai?.people?.length && node.data && node.data.ai && node.data.ai.status !== "done"
            }

            let isMissionOk = !missionFilter || missionFilter?._id === node.data.ai.missionId

            const mission = missionRef.current[node.data?.ai?.missionId]
            const org = mission ? orgRef.current[getObjectId(mission.org)] : null

            const isOrgOk = !orgFilter || getObjectId(org) === orgFilter._id

            const isPinnedOk = !showPinned || node.data.pinned

            let isDoneOk = true

            if (hideDone === "open") {
                isDoneOk = node.data?.ai.status !== "done"
            } else if (hideDone === "done") {
                isDoneOk = node.data?.ai.status === "done"
            }

            return isStatusOk && isMissionOk && personOk && isOrgOk && isDoneOk && isPinnedOk && isDepOk
        },
        [personFilter, missionFilter, statusFilter, orgFilter, hideDone, showPinned, departmentFilter]
    )

    useDebouncedEffect(
        () => {
            gridRef.current?.api?.onFilterChanged()

            setTimeout(() => {
                let c = 0
                gridRef.current?.api?.forEachNodeAfterFilter((n) => {
                    c++
                })
                setTotalTasks(c)
            }, 20)

            localStorage.setItem(
                `x-tm-filters-${app.state.person._id}`,
                JSON.stringify({
                    personFilter,
                    missionFilter,
                    statusFilter,
                    departmentFilter,
                    orgFilter,
                    hideDone,
                    showPinned,
                })
            )
        },
        [personFilter, missionFilter, statusFilter, orgFilter, hideDone, showPinned, departmentFilter],
        100
    )

    useDebouncedEffect(
        () => {
            setColumnDefs([
                {
                    field: "pinned",
                    headerName: "",
                    maxWidth: 25,
                    suppressMovable: true,
                    cellStyle: { paddingRight: 0, cursor: "pointer" },
                    cellRenderer: (params) => {
                        return params.value ? (
                            <PiPushPinDuotone color="orange" />
                        ) : (
                            <PiPushPinDuotone style={{ opacity: 0.2 }} />
                        )
                    },
                },
                {
                    field: "taskId",
                    maxWidth: 60,
                    hide: true,
                    suppressMovable: true,
                },
                {
                    field: "personName",
                    hide: true,
                    suppressMovable: true,
                },
                {
                    field: "taskTitle",
                    headerName: "Action items",
                    flex: 1,

                    suppressMovable: true,
                    cellStyle: {
                        fontWeight: "normal",
                        fontSize: 14,
                        letterSpacing: 0.3,
                    },
                    cellRenderer: TaskCell,
                    cellRendererParams: {
                        orgRef,
                        missionRef,
                        app,
                    },
                },

                {
                    field: "mission",
                    suppressMovable: true,
                    flex: 1,
                    hide: true,
                    minWidth: 240,
                },
                {
                    suppressMovable: true,
                    minWidth: 180,
                    maxWidth: 180,
                    cellRenderer: DetailsCell,
                },
                {
                    field: "seen",
                    suppressMovable: true,
                    hide: true,
                    sortable: true,
                    sort: "desc",
                    maxWidth: 126,
                    minWidth: 126,
                    cellRenderer: (params) => {
                        return params.data?.seen ? (
                            formatDistanceToNowStrict(toZonedTime(params.data.seen)) + " ago"
                        ) : (
                            <span>Not seen</span>
                        )
                    },
                },
                {
                    field: "dueDate",
                    hide: true,
                    headerName: "Due date",
                    suppressMovable: true,
                    maxWidth: 130,
                    cellRenderer: (params) => {
                        return params.data?.dueDate ? format(params.data.dueDate, "EEEEEE. MMM dd, yyyy") : <></>
                    },
                    sortable: true,
                },
                {
                    field: "process",
                    suppressMovable: true,
                    hide: true,
                    headerName: "Progress status",
                },
                {
                    field: "status",
                    headerName: "",
                    hide: true,
                    suppressMovable: true,
                    maxWidth: 120,
                },
                {
                    field: "overdue",
                    suppressMovable: true,
                    hide: true,
                },
                {
                    field: "blocked",
                    suppressMovable: true,
                    hide: true,
                },
            ])
        },
        [stateChange?.timestamp],
        200
    )

    useEffect(() => {
        if (gridRef.current) {
            gridRef.current.columnApi?.applyColumnState({
                state: [{ colId: sort, sort: "desc" }],
                defaultState: { sort: null },
            })
        }
    }, [sort])

    useEffect(() => {
        let peeps = uniqBy(
            flatMap(
                app.state.missions.filter((m) => !m.isTemplate),
                "people"
            ).filter((p) => p.permission < 3 && p.ref && getRefId(p) !== app.state.person._id),
            "ref._id"
        )
        peopleRef.current = Object.fromEntries(peeps.map((item) => [getRefId(item), item.ref]))

        setPeople(peeps.map((p) => ({ ...p, personName: getPersonName(p) })))
    }, [app.state.missions?.length])

    const defaultColDef = {
        suppressMenu: true,
        flex: 1,
        cellClass: (params) => {
            return params.data?.ai.status === "done" ? "x-tm-done" : undefined
        },
    }

    useEffect(() => {
        setMissionFilter(null)
    }, [departmentFilter])

    useEffect(() => {
        const grd = getData(app, missionRef, personFilter)

        setRowData(grd.data)
    }, [stateChange.timestamp, personFilter])

    const onCellClicked = useCallback((params) => {
        if (params.colDef.field === "pinned") {
            let newPins = params.data.ai.pinned?.slice() || []
            const pinnedIndex = (params.data.ai.pinned || [])?.findIndex((p) => p.by === app.state.person._id)
            if (pinnedIndex === -1) {
                newPins.push({
                    by: app.state.person._id,
                    when: getTimeUtc(new Date()),
                })
            } else {
                newPins.splice(pinnedIndex, 1)
            }

            app.actionItemUpdateX(params.data.ai, {
                pinned: newPins,
            })
        } else if (params.colDef.field === "mission") {
            addParam("mission", params.data.ai.missionId)
        } else {
            app.showActionItemDetails(params.data.ai)
        }
    }, [])

    const canAddTask = () => {
        if (missionFilter && !missionFilter.isTemplate && !missionFilter.isModel && !missionFilter.isProcess) {
            if (missionFilter.projectType === "kanban" || missionFilter.projectType === "simple") {
                const meInMission = missionFilter.people.find((p) => getRefId(p) === app.state.person._id)
                if (meInMission.permission > 0 && meInMission.permission < 3) {
                    return true
                }
            }
        }
        return false
    }

    if (app.state.missions.filter((m) => !m.isTemplate).length === 0) {
        return (
            <div className="x-task-master">
                <div className="x-tm-intro">
                    <h3 style={{ marginLeft: 0 }}>
                        Welcome <span className="light-span">young master</span>
                    </h3>

                    <p style={{ maxWidth: 420 }}>
                        When you create or join projects and begin working on tasks they will appear here for you to
                        command and control.
                    </p>

                    <p className="w-400">
                        For now, please start your mission and create a project. (top left of the site)
                    </p>

                    <img src="/img/astro-zen-x.png" style={{ width: 180, transform: "translate(380px,20px)" }} />
                </div>
            </div>
        )
    }

    return (
        <div className="x-task-master">
            <div className="x-dna-filters  x-tm-filters">
                <Scrollbars>
                    <div className="x-inset-light">
                        <label className="top">Find</label>
                        <input
                            type="text"
                            className="x-qfs-tm"
                            placeHolder="Quick search..."
                            onChange={(e) => {
                                gridRef.current.api.setQuickFilter(e.target.value)
                            }}
                        />
                    </div>
                    <div className="x-inset-light">
                        <label className="top">By person</label>
                        <SearchDropDown
                            data={[
                                { personName: "My action items", _id: "mine" },
                                { personName: "My pinned items", _id: "mine-pinned" },
                                { personName: "Unassigned tasks", _id: "unassigned" },
                                ...sortAlpha(people || [], "personName"),
                            ]}
                            value={
                                !personFilter
                                    ? ""
                                    : personFilter === app.state.person._id && showPinned
                                    ? "My Pinned tasks"
                                    : personFilter === app.state.person._id
                                    ? "My action items"
                                    : personFilter === "unassigned"
                                    ? "Unassigned open tasks"
                                    : getPersonName(peopleRef.current[personFilter])
                            }
                            wrapperClassName={cx({ "x-f-red": personFilter })}
                            labelKey={"personName"}
                            menuWidth={250}
                            isItemSelectable={(item) => {
                                return !item.data.header
                            }}
                            placeHolder={"Everyone's tasks..."}
                            showClearIcon={true}
                            noAlpha={true}
                            onSelect={(label, obj) => {
                                if (!obj) return

                                if (obj?._id === "mine" || obj?._id === "mine-pinned") {
                                    setPersonFilter(app.state.person._id)

                                    if (obj?._id === "mine-pinned") {
                                        setShowPinned(true)
                                    } else {
                                        setShowPinned(false)
                                    }
                                } else if (obj?._id === "unassigned") {
                                    setShowPinned(false)
                                    setPersonFilter("unassigned")
                                } else {
                                    setPersonFilter(getRefId(obj))
                                    setShowPinned(false)
                                }
                            }}
                            onClear={() => {
                                setPersonFilter(null)
                                setShowPinned(false)
                            }}
                        />

                        <label>By status</label>

                        <SearchDropDown
                            data={[
                                { label: "Overdue", id: "overdue" },
                                { label: "Blocked", id: "blocked" },
                                { label: "Due today", id: "due-today" },
                                { label: "Due tomorrow", id: "due-tomorrow" },
                                { label: "Due this week", id: "due-this-week" },
                                { label: "In progress", id: "in-progress" },
                                { label: "Not being worked on", id: "not-planned" },
                                { label: "Complete", id: "done" },
                                { label: "CR awaiting approval", id: "cr-open" },
                                { label: "Approved CRs", id: "cr-approved" },
                            ]}
                            noAlpha
                            selectedObj={statusFilter}
                            wrapperClassName={cx({ "x-f-orange": statusFilter })}
                            placeHolder="Select a status..."
                            menuWidth={250}
                            labelKey="label"
                            onSelect={(label, obj) => {
                                setStatusFilter(obj)
                            }}
                            selectOnBlur={false}
                            showClearIcon={true}
                            onClear={() => {
                                setStatusFilter(null)
                            }}
                        />
                    </div>
                    <div className="x-inset-light">
                        {app.state.orgs.length > 1 && (
                            <>
                                <label className="top">By Organization</label>
                                <SearchDropDown
                                    data={app.state.orgs}
                                    wrapperClassName={cx({ "x-f-orange": orgFilter })}
                                    menuWidth={300}
                                    placeHolder="All Organizations..."
                                    labelKey="title"
                                    selectedObj={orgFilter}
                                    onSelect={(label, obj) => {
                                        if (!obj) return
                                        setDepartmentFilter(null)
                                        setOrgFilter(obj)
                                        setMissionFilter(null)
                                    }}
                                    selectOnBlur={false}
                                    showClearIcon={true}
                                    onClear={() => {
                                        setDepartmentFilter(null)
                                        setOrgFilter(null)
                                    }}
                                />
                            </>
                        )}
                        {(orgFilter?.departments?.length > 0 || oneOrg?.departments?.length > 0) && (
                            <>
                                <label className={app.state.orgs.length < 2 ? "top" : undefined}>By department</label>
                                <DepartmentFilter
                                    key={(orgFilter || oneOrg)?._id}
                                    setDepartmentFilter={setDepartmentFilter}
                                    departmentFilter={departmentFilter}
                                    orgData={orgFilter || oneOrg}
                                    app={app}
                                    singleResultStyle={{ margin: 0 }}
                                    widthClass={"w-full"}
                                />
                            </>
                        )}

                        <label>By project</label>
                        <SearchDropDown
                            data={app.state.missions
                                .filter(
                                    (m) =>
                                        !m.isTemplate &&
                                        !m.isModel &&
                                        (!orgFilter || getObjectId(m.org) === getObjectId(orgFilter)) &&
                                        (!departmentFilter || m.departmentTags?.includes(departmentFilter?._id))
                                )
                                .map((m) => ({ ...m, title: `${m.projectCode ? m.projectCode : ""} ${m.title}` }))}
                            wrapperClassName={cx({ "x-f-orange": missionFilter })}
                            menuWidth={300}
                            placeHolder="All projects..."
                            labelKey="title"
                            selectedObj={missionFilter}
                            onSelect={(label, obj) => {
                                setMissionFilter(obj)
                            }}
                            selectOnBlur={false}
                            showClearIcon={true}
                            onClear={() => {
                                setMissionFilter(null)
                            }}
                        />
                    </div>

                    <CheckBoxes
                        radio
                        controlled
                        data={[
                            {
                                label: "Show all tasks",
                                id: "all",
                                checked: hideDone === "all" || !hideDone,
                            },
                            {
                                label: "Open tasks",
                                id: "open",
                                checked: hideDone === "open",
                            },
                            {
                                label: "Completed tasks",
                                id: "done",
                                checked: hideDone === "done",
                            },
                        ]}
                        onChange={(a, b, c, obj) => {
                            if (!c) {
                                setHideDone("all")
                            } else {
                                setHideDone(obj?.id)
                            }
                        }}
                    />
                </Scrollbars>
                <div className="x-action-bg"></div>
            </div>
            <div className="w-full">
                <div className="x-tm-header">
                    <h2>
                        {numeral(totalTasks).format("0,0")} matching {`task${totalTasks === 1 ? "" : "s"}`}
                    </h2>

                    <ul className="x-tm-sort">
                        <li onClick={() => setSort("seen")} className={cx(sort === "seen" && "active")}>
                            Last viewed <AiFillCaretDown />
                        </li>
                        <li onClick={() => setSort("dueDate")} className={cx(sort === "dueDate" && "active")}>
                            Due date <AiFillCaretDown />
                        </li>
                    </ul>
                </div>
                <div className={"ag-theme-alpine dna-ag-grid x-t-grid"}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        getRowId={getRowId}
                        rowData={rowData}
                        suppressContextMenu={true}
                        getRowHeight={getRowHeight}
                        headerHeight={0}
                        animateRows={true}
                        defaultColDef={defaultColDef}
                        onGridReady={(params) => params.api.onFilterChanged()}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                        onCellClicked={onCellClicked}
                        noRowsOverlayComponent={() => {
                            return (
                                <div className="dna-col">
                                    <h2 style={{ marginBottom: 10 }}>🌘</h2>
                                    <p>Nothing to see here... create more action items.</p>
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

const getData = (app, missionRef, personFilter) => {
    let data = []

    let allActionItems = app.state.missions
        .filter((m) => !m.isTemplate && !m.isModel)
        .flatMap((m) => m.planItems)
        .flatMap((p) => p.actions)
        .flatMap((a) => a.actionItems)
        .filter((ai) => !!ai)

    const missionMap = new Map(app.state.missions.map((m) => [m._id, m]))

    const flatPlanItems = flatMap(missionRef.current, "planItems")
    const flatPlanRoles = flatPlanItems.filter((p) => p.type === "person" && p.raciOnTasks?.length)

    flatPlanRoles.forEach((pi) => {
        if (missionMap.get(pi.missionId)?.projectType === "mx-gantt") {
            pi.raciOnTasks.forEach((pr) => {
                const foundAi = allActionItems.find((a) => a._id === pr.taskId)

                if (foundAi) {
                    if (foundAi.people?.find((p) => getRefId(p) === getRefId(pi.person))) {
                        foundAi.people = [...foundAi.people, ...[getRefId(pi.person)]]
                    } else {
                        foundAi.people = [...[getRefId(pi.person)]]
                    }
                }
            })
        }
    })

    allActionItems.forEach((ai, i) => {
        if (!ai) return
        const mission = missionRef.current[ai?.missionId]
        if (!mission) return
        const overdue = isOverdue(ai)

        let status = overdue ? "overdue" : ai?.status === "blocked" ? "blocked" : ai.status === "done" ? "done" : "open"

        if (status !== "done") {
            if (ai.col === "todo") {
                status = "not-planned"
            } else {
                status = "in-progress"
            }
        }

        const col = mission?.cols?.find((c) => c.id === ai.col)

        const who = personFilter === "mine" ? app.state.person._id : personFilter?.length > 7 ? personFilter : null

        const firstSeen = (ai.seen || []).sort((a, b) => b?.when - a?.when)

        const seen = (ai.seen || []).find((s) => {
            if (who) {
                return s.by === getRefId(who)
            }

            if (ai.people.find((p) => getRefId(p) === app.state.person._id)) {
                return s.by === app.state.person._id
            }

            if (firstSeen) {
                return firstSeen._id === s._id
            }

            return false
        })

        const isPinned = ai.pinned?.find((p) => p.by === app.state.person._id)
        const tId = getAiCode(ai)
        const obj = {
            _id: ai._id,
            taskId: tId,
            ai,
            mission: mission,
            missionTitle: (mission.projectCode ? mission.projectCode + "-" : "") + " " + mission.title,
            taskTitle: tId + " " + ai.title,
            dueDate: findAiEndDate(ai)?.getTime() || 0,
            seen: seen,
            isOverdue: overdue ? 1 : 0,
            permissions: actionItemPermissions(ai),
            status: status,
            process:
                mission.projectType !== "mx-gantt" && !ai.week
                    ? "Not scheduled"
                    : col?.id === "done"
                    ? ""
                    : col?.id === "todo"
                    ? "Not started"
                    : col?.name,
            pinned: Boolean(isPinned) ? 1 : 0,
        }

        data.push(obj)
    })

    return {
        data: data,
    }
}

const DetailsCell = (params) => {
    const { ai, seen, dueDate, isOverdue, mission } = params.data

    let status = " "

    if (ai.status === "blocked") {
        status = <div className="dna-pill-box red-pill">Blocked</div>
    } else if (isOverdue === 1) {
        status = <div className="dna-pill-box orange-pill">Overdue</div>
    } else if (ai.status === "done") {
        status = <div className="dna-pill-box green-pill">Done</div>
    } else if (dueDate || ai.week) {
        status = <div className="dna-pill-box blue-pill">In progress</div>
    } else {
        status = <div className="dna-pill-box">To do</div>
    }

    const seenBy = seen?.by ? getPersonName(mission.people.find((p) => getRefId(p) === seen.by)) : undefined

    return (
        <div className="x-tm-ai-details">
            {dueDate > 0 && (
                <p className="x-tm-dd">
                    <PiClockCountdownDuotone color="orange" /> {format(dueDate, "eee. MMM, do, yyyy")}
                </p>
            )}
            {status}
            {!seen ? (
                <p>Nobody's viewed this yet</p>
            ) : (
                <p>
                    Last viewed {formatDistanceToNowStrict(toZonedTime(seen.when)) + " ago"}{" "}
                    {seenBy && <>by {seenBy}</>}
                </p>
            )}
        </div>
    )
}

const TaskCell = (params) => {
    const { ai, mission, missionTitle, app } = params.data

    const progress = ai.checklist?.filter((c) => c.done)?.length || 0

    const people = mission?.people.filter((p) => p.permission < 3)
    const aiPeople = (params.data?.ai?.people || [])
        .map((ap) => people.find((p) => getRefId(p) === ap))
        .filter((p) => !!p)

    const org = params.orgRef.current[mission.org?._id]

    return (
        <div>
            <div className="x-tm-ai-title">
                <b className="x-clamp-2">
                    {getAiCode(ai)} {ai.title || "No title specified..."}
                </b>
            </div>
            <div className="dna-smaller-text x-clamp-1">{missionTitle}</div>
            <div className="dna-progress-bar-x  w-100">
                <div style={{ width: (progress / ai.checklist?.length || 0) * 100 + "%" }}></div>
            </div>
            <Hive
                style={{ marginTop: 10 }}
                round
                ai={ai}
                mission={mission}
                people={aiPeople}
                width={26}
                orgData={org}
                maxLength={8}
                app={params.app}
            />
        </div>
    )
}

TaskMaster.displayName = "TaskMaster"

export default TaskMaster
