import { getMissionStatus, getObjectId, isMissionComplete, ensureNumber } from "../utils/utils"
import { isMissionBillable, missionFinancials } from "../utils/financials"
import { getAllActionItems } from "../utils/actionItem"
import { differenceInWeeks, differenceInDays, startOfDay, isAfter } from "date-fns"
import { toZonedTime } from "date-fns-tz"
import { getTimeUtc } from "../utils/dates"

export const completeMission = (mission, app) => {
    app.missionUpdate(mission._id, {
        projectStatus: "completed",
        missionStop: getTimeUtc(new Date()),
    })
}
export const openMission = (mission, app) => {
    app.missionUpdate(mission._id, {
        projectStatus: "active",
        missionStop: null,
    })
}

export function getWBS(packages, nodeId) {
    const packageMap = new Map(packages.map((pkg) => [pkg._id, pkg]))

    if (!packageMap.has(nodeId)) {
        return ""
    }

    let wbs = []
    let current = packageMap.get(nodeId)

    while (current) {
        const parent = packageMap.get(current.parentId)

        if (!parent) {
            wbs.unshift(`${current.position + 1}`)
        } else {
            // Get all siblings, properly sorted
            const siblings = packages
                .filter((pkg) => pkg.parentId === current.parentId)
                .sort((a, b) => {
                    if (a.isAction !== b.isAction) {
                        return a.isAction ? 1 : -1 // Ensure packages before actions
                    }
                    return a.sortIndex - b.sortIndex
                })

            // Find the current node's position among its siblings
            const index = siblings.findIndex((pkg) => pkg._id === current._id)
            wbs.unshift(`${index + 1}`)
        }

        current = parent
    }

    return wbs.join(".")
}

export function getSortedPackages(packages) {
    // Step 1: Build a lookup map
    const packageMap = new Map(packages.map((pkg) => [pkg._id, { ...pkg, children: [] }]))

    // Step 2: Organize packages into a tree
    let rootPackages = []
    for (const pkg of packages) {
        const node = packageMap.get(pkg._id)
        if (!pkg.parentId) {
            rootPackages.push(node)
        } else {
            const parent = packageMap.get(pkg.parentId)
            if (parent) {
                parent.children.push(node)
            }
        }
    }

    // Step 3: Sort each level by isAction (activities first) then original sortIndex
    // Step 3: Sort each level by isAction, then position, then createdAt
    function sortTree(nodes) {
        nodes.sort((a, b) => {
            const aIsAction = !!a.isAction
            const bIsAction = !!b.isAction
            if (aIsAction !== bIsAction) {
                return aIsAction - bIsAction // Activities before packages
            }
            // Sort by position, then createdAt
            return (a.position ?? Infinity) - (b.position ?? Infinity)
        })
        nodes.forEach((node) => sortTree(node.children))
    }
    sortTree(rootPackages)

    // Step 4: Flatten tree into a sorted list
    let sortedPackages = []
    function flattenTree(nodes) {
        for (const node of nodes) {
            const { children, ...pkg } = node
            sortedPackages.push(pkg)
            flattenTree(children)
        }
    }
    flattenTree(rootPackages)

    // Step 5: Reassign sortIndex sequentially based on flattened order
    const sP = sortedPackages.map((pkg, index) => ({
        ...pkg,
        sortIndexDate: index + 1, // Sequential sortIndex
    }))

    // Step 6: Assign WBS using the sorted sP array
    return sP.map((pkg) => ({
        ...pkg,
        wbs: getWBS(sP, pkg._id),
    }))
}

export const estimatedCompletionDate = () => {}

export function missionWarnings({ missions, app, orgData }) {
    const data = []

    missions.forEach((mission) => {
        const status = getMissionStatus(mission)
        if (status !== "active" || isMissionComplete(mission)) return

        const isBillable = isMissionBillable(mission)
        const org = orgData || app.state.orgs?.find((o) => getObjectId(o) === getObjectId(mission.org))
        const mf = missionFinancials({ mission, app, orgData: org })

        if (isBillable && mission.budgetAvailable && mission.budgetAvailable !== mf.estRevenue) {
            data.push(
                createWarning("undefined-budget", 2, mission, {
                    budgetAvailable: mission.budgetAvailable,
                    definedBudget: mf.estRevenue,
                })
            )
        }

        mission.planItems.forEach((pi) => {
            if (pi.type === "person" && !pi.person) {
                const startDate = toZonedTime(pi.startDate)
                const today = startOfDay(new Date())
                const diffWeeks = differenceInWeeks(today, startDate)
                const daysTo = differenceInDays(startDate, new Date())

                if (isAfter(startDate, today) && daysTo <= 10) {
                    data.push(createWarning("unfilled-role", daysTo > 5 ? 2 : 3, mission, { pi, startDate }))
                } else if (diffWeeks <= 0) {
                    data.push(createWarning("unfilled-role", 3, mission, { pi, startDate: today }))
                } else if (diffWeeks <= 2) {
                    data.push(createWarning("unfilled-role", 2, mission, { pi, startDate }))
                }
            }
        })
    })

    return data
}

function createWarning(type, severity, mission, additionalFields = {}) {
    return {
        date: isAfter(new Date(), toZonedTime(mission.endDate))
            ? startOfDay(toZonedTime(mission.endDate)).getTime()
            : startOfDay(new Date()).getTime(),
        type,
        severity,
        mission,
        missionId: mission._id,
        ...additionalFields,
    }
}
