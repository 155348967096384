import React, { useState, useEffect } from "react"
import DropDown from "../comp/DropDown"
import Button from "../comp/Button"
import apiOrg from "../services/org/org.api"
import { toast } from "react-toastify"
import { addParam, addParams, removeParams } from "../comp/History"
import { PiTriangleDuotone, PiHeartDuotone } from "react-icons/pi"
import apiAuth from "../services/auth/auth.api"
import { createAppSumoSubscription } from "../services/org/org.stripe.api"
import "./AppSumoFlow.scss"

const isV = (app, planDetails) => {
    return app.state.person?.emailList.find((em) => em.email === planDetails?.activation_email)?.verified
}

const AppSumoFlow = ({ app, onClose, planDetails, oobCode }) => {
    const [verified, setVerified] = useState(isV(app, planDetails))

    const { orgs } = app.state
    const chooseOrg = (label, obj) => {
        applyAppSumoPackageToOrg({ org: obj.org, app, planDetails, oobCode, onClose })
    }

    useEffect(() => {
        if (!planDetails || !oobCode) {
            const checkLs = localStorage.getItem("mx-app-sumo-oobCode-" + app.state.person._id)

            oobCode = oobCode || JSON.parse(checkLs)?.oobCode
            apiOrg.getAppSumoByOobCode(oobCode).then((res) => {
                planDetails = res

                const ver = isV(app, planDetails)

                setVerified(ver)
            })
        }
    }, [planDetails, oobCode])

    return (
        <div className="x-sumo-flow dna-col">
            <div className="x-summo-header ">
                <img src="/promos/AppSumoLogo.png" />
                <img src="/img/astro-yeahy.png" />
            </div>
            <h1>You did it!</h1>

            <p>
                <PiHeartDuotone /> Thank you so much for your support.
            </p>

            <p>
                <b>But before we start...</b>
            </p>
            {verified ? (
                <>
                    <div className="w-300">
                        <p>
                            In missionX an{" "}
                            <a href="https://missionx.ai/guide/getting-started/workspaces/" target="_blank">
                                Organization workspace
                            </a>{" "}
                            represents your entire company. This is why{" "}
                            <b>you can only apply your AppSumo credits to one organization.</b>{" "}
                        </p>
                        <p>
                            Basically, you should only be using one org space to manage all projects and people for your
                            business.
                        </p>
                    </div>
                    <br />
                    <p>So which organization shall we apply your credits to?</p>
                    <div className="dna-center" style={{ marginTop: 20 }}>
                        {orgs.length > 0 && (
                            <>
                                <DropDown
                                    className="w-300"
                                    inputProps={{ placeHolder: "Select an existing org..." }}
                                    items={orgs.map((o) => {
                                        return {
                                            label: o.title,
                                            org: o,
                                        }
                                    })}
                                    onSelect={chooseOrg}
                                />
                                &nbsp;
                            </>
                        )}

                        <Button
                            dark={orgs.length === 0}
                            onClick={() => {
                                /*    if (!verified) {
                                    if (!toast.isActive("summoKiller")) {
                                        toast(
                                            "To activate your AppSumo package you must first validate your email address. We have sent a verification email, or you can request a request a new email from your profile (top right on the avatar).",
                                            {
                                                autoClose: false,
                                                toastId: "summoKiller",
                                            }
                                        )
                                    }

                                    addParam("myspace", "open")

                                    return
                                } else {*/
                                onClose()
                                addParams([
                                    {
                                        param: "hq",
                                        value: "open",
                                    },
                                    {
                                        param: "appsumo-email",
                                        value: planDetails.activation_email,
                                    },
                                ])
                            }}
                        >
                            new Organization
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <p>
                        <PiTriangleDuotone /> Please verify: <b>{planDetails?.activation_email}</b>
                    </p>
                    <p className="w-300">
                        Check your email and follow the link we sent, or{" "}
                        <span
                            className="dna-issue-text dna-cursor x-link"
                            onClick={() => {
                                if (app.state.person.email === planDetails.activation_email) {
                                    apiAuth
                                        .sendEmailVerification()
                                        .then(() => {
                                            toast.success("Verification email on it's way!")
                                        })
                                        .catch((err) => {
                                            toast.error("Already on its way. Please check your email.")
                                        })
                                } else {
                                    apiAuth
                                        .emailListSendVerification(planDetails.activation_email)
                                        .then((res) => {
                                            toast.success("Thank you. Please check your email and follow the link.")
                                        })
                                        .catch((err) => {
                                            toast.error("Already on its way. Please check your email.")
                                        })
                                }
                            }}
                        >
                            request a new verification email.
                        </span>
                    </p>
                </>
            )}

            <p style={{ marginTop: 40 }} className="dna-label dna-cursor" onClick={onClose}>
                I'll do this later
            </p>
        </div>
    )
}

export const applyAppSumoPackageToOrg = async ({ org, app, planDetails, oobCode, onClose }) => {
    const { person } = app.state

    if (!planDetails || !oobCode) {
        const checkLs = localStorage.getItem("mx-app-sumo-oobCode-" + app.state.person._id)

        oobCode = oobCode || JSON.parse(checkLs)?.oobCode

        if (!oobCode) {
            toast.warning(
                "I'm sorry but I did not find your activation details. Please visit AppSumo and try the activation again but first contact us on the chat widget."
            )
            return
        }

        planDetails = await apiOrg.getAppSumoByOobCode(oobCode).catch((err) => {
            toast.error(err?.message)
            return
        })
    }

    app.confirm({
        severe: false,
        comp: (
            <div>
                <p>Great!</p>
                <p>This will apply your credits to "{org.title}"</p>
                <p>Proceed?</p>
            </div>
        ),
        yesText: "Yes! Just do it already",
        onYes: () => {
            app.block()

            const name = person.firstName + " " + person.lastName

            if (!planDetails) {
                toast(
                    "An error occured. Please refresh and give another try otherwise reach out to us on the chat widget or help@missionx.ai"
                )
                return
            }

            createAppSumoSubscription(org._id, oobCode, {
                customer: {
                    email: planDetails?.activation_email,
                    name: name,
                },
            })
                .then((response) => {
                    app.unBlock()

                    const orgChange = response.org
                    app.orgUpdateState(org, orgChange)

                    removeParams("from,oobCode,email, claim-appsumo, appsumo-email")

                    addParam("org", org._id)

                    toast.success("Thank you! Let us know if we can help.")

                    clearAppSumoDetails({ app })

                    if (onClose) onClose()

                    app.unBlock()
                })
                .catch((err) => {
                    app.silentFail(
                        "appsume-fauil" + err?.message + " oob:" + oobCode + " " + planDetails?.activation_email
                    )

                    if (err.code === "no_permission") {
                        clearAppSumoDetails({ app })
                    }

                    console.error(err)
                    toast.error(err.message)
                    app.unBlock()
                })
        },
    })
}

export function clearAppSumoDetails({ app }) {
    let newArr = app.state.person.uiMessageFlags || []

    let summoIndex = newArr.indexOf("appsumo")

    if (summoIndex !== -1) {
        newArr.splice(summoIndex, 1)
        app.personUpdate({ uiMessageFlags: newArr })
    }
    localStorage.removeItem("mx-app-sumo-oobCode-" + app.state.person._id)
    app.setState({
        appsumo: null,
        appSumoOobCode: null,
        myspace: null,
    })
    removeParams("from,oobCode,appsumo-email,email")
}

export default AppSumoFlow
