import React, { useState, useEffect } from "react"
import { isArchived, getRefId, getObjectId } from "../utils/utils"
import SearchDropDown from "../comp/SearchDropDown"
import intersection from "lodash/intersection"
import cx from "classnames"
import uniq from "lodash/uniq"
import { toast } from "react-toastify"
import ReactTags from "react-tag-autocomplete"
import { PiTriangleDuotone } from "react-icons/pi"
import "./orgFilters.scss"

export const DepartmentFilter = ({
    orgData,
    setDepartmentFilter,
    departmentFilter,
    meInOrg,
    hideEmpty,
    className,
    app,
    widthClass = "w-200",
    singleResultStyle,
}) => {
    const isArr = Array.isArray(departmentFilter)
    const [value, setValue] = useState("")

    if (!meInOrg && app) {
        meInOrg = orgData.people.find((p) => getRefId(p) === app.state.person._id)
    } else {
        meInOrg = {}
    }

    if (!meInOrg) {
        meInOrg = {}
    }

    const myDeps = (orgData.departments || []).filter((d) => {
        if (meInOrg.accessibleDepartments?.length) {
            return meInOrg.accessibleDepartments?.includes(d._id)
        } else {
            return true
        }
    })

    const myActiveDeps = (myDeps || []).filter((d) => !isArchived(d))
    const myAcrhivedDeps = (myDeps || []).filter((d) => isArchived(d))

    const [data] = useState([...myActiveDeps, ...myAcrhivedDeps])
    const onClear = () => {
        if (isArr) {
            setDepartmentFilter([])
        } else {
            setDepartmentFilter(null)
        }

        setValue("")
    }

    if (data.length === 1 && meInOrg.accessibleDepartments?.length === 1) {
        return <div style={singleResultStyle || { margin: "0px 10px 0px 10px" }}>{data[0].title}</div>
    }

    if (hideEmpty && !data?.length) {
        return <></>
    }

    return (
        <SearchDropDown
            wrapperClassName={cx(
                widthClass,
                { "x-field-orange": isArr ? departmentFilter?.length : departmentFilter },
                className
            )}
            data={data}
            value={value}
            onSelect={(label, multiArray) => {
                setValue(label)

                if (isArr) {
                    setDepartmentFilter(multiArray || [])
                } else {
                    setDepartmentFilter(multiArray)
                }
            }}
            noAlpha
            selectOnBlur={false}
            multiData={isArr ? departmentFilter : undefined}
            labelKey="title"
            menuWidth={300}
            onClear={onClear}
            showClearIcon={true}
            placeHolder="Departments..."
            template={(d) => {
                return <div className="dna-smaller-text dna-issue-text">{isArchived(d) ? "No longer active" : ""}</div>
            }}
        />
    )
}
export const OfficeFilter = ({ orgData, setOfficeFilter, officeFilter, meInOrg = {}, hideEmpty, className }) => {
    const isArr = Array.isArray(officeFilter)
    const [value, setValue] = useState("")
    const myOffices = (orgData.offices || []).filter((o) => {
        if (meInOrg.accessibleOffices?.length) {
            return meInOrg.accessibleOffices?.includes(o._id)
        } else {
            return true
        }
    })

    const [data] = useState([
        ...(myOffices || []).filter((d) => !isArchived(d)),
        ...(myOffices || []).filter((d) => isArchived(d)),
    ])
    const onClear = () => {
        if (isArr) {
            setOfficeFilter([])
        } else {
            setOfficeFilter(null)
        }
        setValue("")
    }

    if (data.length === 1 && meInOrg.accessibleOffices?.length === 1) {
        return <div style={{ margin: "0px 10px 0px 10px" }}>{data[0].title}</div>
    }

    if (hideEmpty && !data?.length) {
        return <></>
    }
    return (
        <SearchDropDown
            wrapperClassName={cx("w-200", { "x-field-orange": isArr ? officeFilter?.length : officeFilter }, className)}
            data={data}
            value={value}
            onSelect={(label, multiArray) => {
                setValue(label)

                if (isArr) {
                    setOfficeFilter(multiArray || [])
                } else {
                    setOfficeFilter(multiArray)
                }
            }}
            noAlpha
            selectOnBlur={false}
            multiData={isArr ? officeFilter : undefined}
            labelKey="title"
            menuWidth={300}
            onClear={onClear}
            showClearIcon={true}
            placeHolder="Offices..."
            template={(d) => {
                return <div className="dna-smaller-text dna-issue-text">{isArchived(d) ? "No longer active" : ""}</div>
            }}
        />
    )
}
export const ProgramFilter = ({
    programFilter,
    setProgramFilter,
    orgData = {},
    meInOrg = {},
    className = "w-250",
    onSelect,
    noHighlight,
    hideEmpty,
}) => {
    const ourGoals =
        orgData.goals?.filter((g) => {
            if (meInOrg.permission === 2) {
                return true
            }

            let officeOk
            let depOk

            if (g.departments?.length) {
                if (meInOrg?.departments?.length && g.departments.includes(meInOrg?.departments[0])) {
                    depOk = true
                }
            } else {
                depOk = true
            }

            if (g.offices?.length) {
                if (meInOrg?.office && g.offices?.includes(meInOrg?.office)) {
                    officeOk = true
                }
            } else {
                officeOk = true
            }

            return officeOk && depOk
        }) || []

    const getGoalPrograms = () => {
        const myPrograms = ourGoals?.length
            ? (orgData.programs || [])?.filter((p) => {
                  if (!p.portfolio || p.isDeleted) return false
                  return ourGoals?.filter((og) => {
                      return og._id === p.portfolio
                  })?.length
              })
            : []

        return myPrograms
    }
    const gp = getGoalPrograms()
    if (hideEmpty && !gp?.length) {
        return <></>
    }
    return (
        <SearchDropDown
            wrapperClassName={cx({ "x-field-orange": programFilter && !noHighlight }, className)}
            placeHolder="Programs..."
            selectedObj={programFilter}
            onSelect={(label, obj) => {
                setProgramFilter(obj)
                if (onSelect) {
                    onSelect(obj)
                }
            }}
            data={gp}
            labelKey="title"
            showClearIcon={true}
            onClear={() => {
                setProgramFilter(null)
                if (onSelect) {
                    onSelect(null)
                }
            }}
            template={(d) => {
                return (
                    <div className="dna-smaller-text dna-light-text">
                        <div className="x-clamp-2">{ourGoals.find((g) => g._id === d.portfolio)?.title}</div>
                        {isArchived(d) && <div className="dna-issue-text">No longer active</div>}
                    </div>
                )
            }}
        />
    )
}

export const getOnlyMyDepartments = ({ orgData, meInOrg }) => {
    if (!orgData || !meInOrg) {
        return []
    }

    if (meInOrg.permission === 2) {
        return orgData.departments?.filter((d) => !isArchived(d)) || []
    }

    if (meInOrg.accessibleDepartments?.length) {
        return (
            meInOrg?.accessibleDepartments
                .map((adId) => orgData.departments?.find((d) => !isArchived(d) && d._id === adId))
                ?.filter((o) => !!o) || []
        )
    }

    return []
}

export const getOnlyMyOffices = ({ orgData, meInOrg }) => {
    if (!orgData || !meInOrg) {
        return []
    }

    if (meInOrg.permission === 2 || !meInOrg.accessibleOffices?.length) {
        return orgData.offices?.filter((o) => !isArchived(o)) || []
    }

    if (meInOrg.accessibleOffices?.length) {
        return (
            meInOrg?.accessibleOffices
                .map((ofId) => orgData.offices?.find((f) => !isArchived(f) && f._id === ofId))
                ?.filter((o) => !!o) || []
        )
    }

    return []
}

export const getOnlyMyPrograms = ({ meInOrg, orgData }) => {
    if (!orgData || !meInOrg) {
        return []
    }
    const ourGoals = orgData.goals?.filter((goal) => {
        if (meInOrg.permission === 2 || meInOrg.canManageGoals) {
            return true
        }

        if (meInOrg.permission === 4) {
            return goal.owner === getRefId(meInOrg)
        }

        if (meInOrg.permission === 1) {
            let officeOk
            let depOk
            if (goal.offices?.length) {
                if (meInOrg.accessibleOffices?.length) {
                    officeOk = Boolean(intersection(meInOrg.accessibleOffices, goal.offices).length)
                } else {
                    officeOk = true
                }
            } else {
                officeOk = true
            }

            if (goal.departments?.length) {
                if (meInOrg.accessibleDepartments?.length) {
                    depOk = Boolean(intersection(meInOrg.accessibleDepartments, goal.departments).length)
                } else {
                    depOk = true
                }
            } else {
                depOk = true
            }

            return depOk && officeOk
        }

        return false
    })

    const myPrograms = orgData.programs?.filter((p) => p.managers?.includes(getRefId(meInOrg))) || []

    const myGoalPrograms =
        ourGoals.length && orgData.programs
            ? orgData.programs?.filter((p) => {
                  if (!p.portfolio || p.isDeleted) return false
                  return ourGoals?.filter((og) => {
                      return og._id === p.portfolio
                  })?.length
              })
            : []

    return [...myPrograms, ...myGoalPrograms].filter((d) => !isArchived(d))
}

export const ProgramSelector = ({
    programFilter,
    setProgramFilter,
    orgData,
    meInOrg = {},
    className = "w-400",
    onSelect,
    noHighlight,
}) => {
    const eligiblePrograms = getOnlyMyPrograms({ orgData, meInOrg })
    return (
        <SearchDropDown
            wrapperClassName={cx({ "x-field-orange": programFilter && !noHighlight }, className)}
            placeHolder="Programs..."
            selectedObj={programFilter}
            onSelect={(label, obj) => {
                if (setProgramFilter) setProgramFilter(obj)
                if (onSelect) {
                    onSelect(obj)
                }
            }}
            data={eligiblePrograms?.filter((p) => p.portfolio) || []}
            labelKey="title"
            showClearIcon={true}
            onClear={() => {
                if (setProgramFilter) setProgramFilter(null)
                if (onSelect) {
                    onSelect(null)
                }
            }}
            template={(d) => {
                return (
                    <div className="dna-smaller-text dna-light-text">
                        <div className="x-clamp-2">{orgData.goals?.find((g) => g._id === d.portfolio)?.title}</div>
                        {isArchived(d) && <div className="dna-issue-text">No longer active</div>}
                    </div>
                )
            }}
        />
    )
}

export const GoalSelector = ({ setGoal, goal, orgData, meInOrg = {}, className }) => {
    const ourGoals = (orgData.goals || [])?.filter((g) => {
        if (meInOrg.permission === 2) {
            return true
        }

        let officeOk
        let depOk

        if (g.departments?.length) {
            if (meInOrg?.departments?.length && g.departments.includes(meInOrg?.departments[0])) {
                depOk = true
            }
        } else {
            depOk = true
        }

        if (g.offices?.length) {
            if (meInOrg?.office && g.offices?.includes(meInOrg?.office)) {
                officeOk = true
            }
        } else {
            officeOk = true
        }

        return officeOk && depOk
    })

    return (
        <SearchDropDown
            wrapperClassName={className}
            placeHolder="Programs..."
            selectedObj={goal}
            onSelect={(label, obj) => {
                setGoal(obj)
            }}
            data={ourGoals()}
            labelKey="title"
            showClearIcon={true}
            onClear={() => {
                ourGoals(null)
            }}
        />
    )
}

export const OfficeSelector = ({
    orgData,
    offices = [],
    programTag,
    app,
    meInOrg = {},
    onDelete,
    onAddition,
    label,
}) => {
    const [isSingleOff, setIsSingleOff] = useState()

    const onAdditionA = (tag) => {
        if (!tag?.name || offices?.includes(tag.id)) {
            return
        }

        const newOfficeTags = uniq(offices || [])
            .slice()
            .filter((d) => !!d || d === "")

        newOfficeTags.push(tag.id)

        onAddition(newOfficeTags)
    }
    const onDeleteA = (tagIndex) => {
        if (tagIndex === -1) return

        if (isSingleOff) {
            toast("Sorry but you are limited to this office")
            return
        }

        const newOfficeTags2 = (offices || []).slice()
        newOfficeTags2.splice(tagIndex, 1)

        onDelete(newOfficeTags2)
    }

    useEffect(() => {
        const officesAvailable = getOnlyMyOffices({ orgData, meInOrg })

        const sd = officesAvailable.length === 1 && meInOrg.accessibleOffices?.length

        if (sd) {
            setIsSingleOff(officesAvailable[0])
        }
    }, [orgData?._id, meInOrg])

    const myOffices = getOnlyMyOffices({ orgData, meInOrg })

    let officeSuggestions =
        myOffices
            ?.filter((orgOffice) => {
                let prOk = false

                if (programTag) {
                    const myProgram = orgData.programs?.find((p) => p._id === programTag)
                    const myGoal = orgData.goals?.find((g) => g._id === myProgram?.portfolio)

                    if (myGoal?.offices?.length) {
                        prOk = myGoal.offices?.includes(orgOffice._id)
                    } else {
                        prOk = true
                    }
                } else {
                    prOk = true
                }

                const existsInTags = offices?.includes(orgOffice._id)

                return prOk && !existsInTags
            })
            ?.map((o) => ({ ...o, name: o.title, id: o._id })) || []

    const missMatch = Boolean(offices?.length > 0 && !offices?.find((o) => myOffices?.find((oo) => o === oo._id)))

    //

    if (!officeSuggestions?.length && !offices?.length) {
        return (
            <>
                {label && label(isSingleOff)}
                <p>
                    No offices available.{" "}
                    {myOffices.length > 0 &&
                        programTag &&
                        "Possibly because the program you selected is limited by what offices can participate."}
                </p>
            </>
        )
    }

    return (
        <div className="x-of-of-tags">
            {label && label(isSingleOff)}

            {isSingleOff ? (
                <>
                    {missMatch ? (
                        <>
                            <p>
                                {offices?.map((oId) => {
                                    return orgData.offices?.find((oo) => oo._id === oId)?.title + " "
                                })}
                            </p>
                            <p style={{ fontSize: 14 }} className="w-400">
                                <PiTriangleDuotone /> These offices are required for this program. Please ask an admin
                                or portfolio manager to alter them if needed.
                            </p>
                        </>
                    ) : (
                        <p>{myOffices?.find((o) => o._id === myOffices[0]?._id)?.title || ""}</p>
                    )}
                </>
            ) : (
                <ReactTags
                    autofocus={false}
                    minQueryLength={0}
                    allowBackspace={false}
                    classNames={{
                        root: "react-tags" + (!officeSuggestions.length ? " no-more-tags" : ""),
                        searchInput: "react-tags__search-input rt-min-200",
                    }}
                    placeholderText="Add an office..."
                    tags={
                        offices
                            .map((d) => {
                                const fo = orgData?.offices?.find((oo) => oo._id === d && !isArchived(d))

                                if (!fo) {
                                    return null
                                }

                                return { name: fo.title, id: fo._id }
                            })
                            ?.filter((o) => !!o) || []
                    }
                    addOnBlur={true}
                    suggestions={officeSuggestions}
                    onDelete={onDeleteA}
                    onAddition={onAdditionA}
                />
            )}
        </div>
    )
}
export const DepartmentSelector = ({
    orgData,
    departmentTags = [],
    app,
    meInOrg = {},
    programTag,
    onDelete,
    onAddition,
}) => {
    const [isSingleDep, setIsSingleDep] = useState()

    const onAdditionA = (tag) => {
        const newDepartments = (departmentTags || []).slice().filter((d) => !!d)
        newDepartments.push(tag.id)

        onAddition(newDepartments)
    }
    const onDeleteA = (tagIndex) => {
        if (tagIndex === -1) return

        if (isSingleDep) {
            toast("Sorry but you are limited to this department")
            return
        }

        const newDepartments2 = (departmentTags || []).slice()
        newDepartments2.splice(tagIndex, 1)

        onDelete(newDepartments2)
    }

    useEffect(() => {
        const departmentsAvailable = (orgData?.departments || []).filter((d) => {
            if (meInOrg.accessibleDepartments?.length) {
                return meInOrg.accessibleDepartments.includes(getObjectId(d._id))
            } else {
                return true
            }
        })

        const sd = departmentsAvailable.length === 1 && meInOrg.accessibleDepartments?.length

        if (sd) {
            setIsSingleDep(departmentsAvailable[0])
        }
    }, [orgData?._id])

    const depSuggestions = (
        orgData?.departments
            ?.filter(
                (orgDepartment) =>
                    !!orgDepartment &&
                    !isArchived(orgDepartment) &&
                    !departmentTags.find((oo) => oo === orgDepartment._id)
            )
            ?.filter((orgDepartment) => {
                let adOk = false
                let prOk = false

                if (meInOrg.accessibleDepartments?.length) {
                    adOk = meInOrg.accessibleDepartments.find((ooo) => ooo === orgDepartment._id)
                } else {
                    adOk = true
                }

                if (programTag) {
                    const myProgram = orgData.programs?.find((p) => p._id === programTag)
                    const myGoal = orgData.goals?.find((g) => g._id === myProgram?.portfolio)
                    if (myGoal?.departments?.length) {
                        prOk = myGoal.departments.includes(orgDepartment._id)
                    } else {
                        prOk = true
                    }
                } else {
                    prOk = true
                }

                const existsInTags = departmentTags.includes(orgDepartment._id)

                return adOk && prOk && !existsInTags
            }) || []
    ).map((o) => ({ ...o, name: o.title, id: o._id }))

    if (!depSuggestions?.length && !departmentTags.length) {
        return <p>No departments available</p>
    }

    return (
        <div className="x-of-dep-tags">
            {isSingleDep ? (
                <p>{isSingleDep.title}</p>
            ) : (
                <ReactTags
                    autofocus={false}
                    minQueryLength={0}
                    allowBackspace={false}
                    classNames={{
                        root: "react-tags" + (!depSuggestions.length ? " no-more-tags" : ""),
                        searchInput: "react-tags__search-input rt-min-200",
                    }}
                    placeholderText="Add department..."
                    tags={
                        departmentTags
                            .map((d) => {
                                const fo = orgData?.departments?.find((oo) => oo._id === d && !isArchived(d))

                                if (!fo) {
                                    return null
                                }

                                return { name: fo.title, id: fo._id }
                            })
                            ?.filter((o) => !!o) || []
                    }
                    addOnBlur={true}
                    suggestions={depSuggestions}
                    onDelete={onDeleteA}
                    onAddition={onAdditionA}
                />
            )}
        </div>
    )
}
