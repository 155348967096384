import React, { useState, useCallback, useEffect, useRef } from "react"
import Highlighter from "react-highlight-words"
import DropDown from "./DropDown"
import Tip from "./Tip"
import Button from "./Button"
import { IoCloseOutline } from "react-icons/io5"
import cx from "classnames"
import "./SearchDropDown.scss"
const SearchDropDown = ({
    value,
    data = [],
    placeHolder,
    className,
    onSelect,
    labelKey = "title",
    maxItems,
    style,
    wrapperStyle,
    inputClassName,
    noAlpha,
    onClear,
    onChange,
    multiData,
    onBlur,
    showClearIcon,
    selectOnBlur = false,
    onMenuVisibilityChange,
    template,
    dropDownProps,
    forceSelect,
    clearTip,
    onKeyPress,
    onKeyDown,
    wrapperClassName,
    menuWidth,
    getItemValue,
    noResultsText,
    noEmptyMenu,
    replaceTemplate,
    autoFocus,
    isItemSelectable,
    open,
    selectedObj,
    shouldItemRender,
    clearValue,
    getRef,
    listClassName,
}) => {
    const mounted = useRef()

    const inputRef = useRef()

    const [initialLabel] = useState(selectedObj ? selectedObj[labelKey] : value)

    const [mySelectedObj, setMySelectedObj] = useState(selectedObj)
    const [selectionMade, setSelectionMade] = useState()
    const [menuOpen, setMenuOpen] = useState(false)
    const [val, setVal] = useState(initialLabel)
    const currentVal = useRef(initialLabel)

    const matchRoleDropDownItem = (item, v) => {
        if (selectionMade) {
            return true
        }
        if (multiData) {
            if (v === currentVal.current) {
                return true
            }
            return item.label.toLowerCase().includes(v.toLowerCase())
        }

        if (val === "" || val === initialLabel) {
            return true
        }

        if (!item.label || item.label === "") {
            return false
        }

        return item.label?.toLowerCase().includes(v?.toLowerCase())
    }

    const onBlurHandeler = (e) => {
        if (forceSelect && !mySelectedObj) {
            if (onBlur) onBlur(e, e.target.value)
            e.target.value = null
            setVal(initialLabel)

            return
        } else if (forceSelect && mySelectedObj) {
            setVal(mySelectedObj[labelKey])
        }
        if (selectOnBlur && mySelectedObj) {
            if (e.target.value.length) {
                setSelectionMade(true)
                onSelect(e.target.value, mySelectedObj)

                setVal(mySelectedObj[labelKey])
            }
            if (onBlur) onBlur(e, e.target.value)
            return
        }

        if (onBlur) onBlur(e, val)
    }

    useEffect(() => {
        if (!mounted.current) {
            return
        }
        setSelectionMade(true)
        currentVal.current = value
        setVal(value)
    }, [value])

    useEffect(() => {
        if (!mounted.current) {
            return
        }
        if (selectedObj) {
            currentVal.current = selectedObj[labelKey]
            setVal(selectedObj[labelKey])
        } else {
            currentVal.current = null
            setVal(null)
        }
    }, [selectedObj])

    useEffect(() => {
        //did mount
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    const menuChange = (isMenuOpen) => {
        setMenuOpen(isMenuOpen)
        if (onMenuVisibilityChange) onMenuVisibilityChange(isMenuOpen)
    }

    function isObject(value) {
        return value !== null && (typeof value === "object" || typeof value === "function")
    }

    let items = (data || [])
        .filter((d) => !!d)
        .map((d) => {
            if (isObject(d)) {
                return { label: d[labelKey] || "No title specified...", id: d._id || d.id, data: d }
            } else {
                return { label: d + "", data: d }
            }
        })

    if (!noAlpha) {
        items = items.sort((a, b) => {
            if (a.label < b.label) {
                return -1
            }
            if (a.label > b.label) {
                return 1
            }
            return 0
        })
    }

    const templateCall = useCallback(
        (obj, isHighlighted, a, c, d) => {
            if (replaceTemplate && !multiData) {
                return template(obj.data, val, isHighlighted)
            }

            return (
                <div
                    className={cx("dna-dd-item", listClassName, {
                        active: obj.isHighlighted,
                    })}
                >
                    {multiData && (
                        <>
                            <input
                                type="checkbox"
                                checked={Boolean(multiData.find((d) => d._id === obj.id || d.id === obj.id))}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                onChange={
                                    forceSelect
                                        ? undefined
                                        : (e) => {
                                              let newMultiSelected = multiData.slice()
                                              if (e.target.checked) {
                                                  newMultiSelected.push(obj.data)
                                              } else {
                                                  const myIndex = newMultiSelected.findIndex(
                                                      (d) => d._id === obj.id || d.id === obj.id
                                                  )
                                                  if (myIndex !== -1) {
                                                      newMultiSelected.splice(myIndex, 1)
                                                  }
                                              }
                                              const newLabel =
                                                  newMultiSelected.length === 1
                                                      ? newMultiSelected[0][labelKey]
                                                      : newMultiSelected.length > 1
                                                      ? "Multiple..."
                                                      : ""

                                              setSelectionMade(true)
                                              onSelect(newLabel, newMultiSelected)
                                          }
                                }
                            />
                        </>
                    )}
                    {multiData && replaceTemplate ? (
                        template(obj.data, val)
                    ) : (
                        <>
                            <Highlighter
                                highlightClassName="dna-highlight-text"
                                searchWords={[val]}
                                autoEscape={true}
                                textToHighlight={obj.label}
                            />
                            {template && template(obj.data, val)}
                        </>
                    )}
                </div>
            )
        },
        [multiData, template, val]
    )

    const content = (
        <DropDown
            style={style}
            items={items}
            className={cx("x-search-drop-down", className, {
                "has-clear-icon": showClearIcon,
                "x-sd-multi": multiData,
            })}
            menuWidth={menuWidth}
            value={selectedObj && !val?.length ? "No title specified..." : val || ""}
            getItemValue={getItemValue}
            selectOnBlur={selectOnBlur}
            autoHighlight={true}
            open={open}
            noEmptyMenu={noEmptyMenu}
            autoFocus={autoFocus}
            isItemSelectable={isItemSelectable}
            noResultsText={noResultsText}
            onChange={(v) => {
                if (multiData) {
                } else {
                    if (onChange) onChange(v)
                    setMySelectedObj(null)
                    if (v === "") {
                        onSelect(null, multiData ? [] : null)
                        setSelectionMade(false)
                    }
                }

                setSelectionMade(false)
                setVal(v)
            }}
            shouldItemRender={shouldItemRender || matchRoleDropDownItem}
            onMenuVisibilityChange={menuChange}
            inputProps={{
                className: inputClassName,
                placeholder: placeHolder || "Select...",
                onBlur: onBlurHandeler,
                onKeyDown: onKeyPress || onKeyDown,
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "off",
                spellcheck: "false",
            }}
            getRef={(el) => {
                if (getRef) {
                    getRef(el?.refs?.input)
                }
                inputRef.current = el?.refs?.input
            }}
            onSelect={
                multiData
                    ? undefined
                    : (v, obj) => {
                          setMySelectedObj(obj?.data)
                          setSelectionMade(true)
                          onSelect(v, obj?.data || null)
                      }
            }
            template={templateCall}
            {...dropDownProps}
        />
    )

    if (!showClearIcon) {
        return content
    } else {
        return (
            <div
                className={cx(wrapperClassName || "w-full")}
                style={{ position: "relative", zIndex: menuOpen ? 2 : 0, ...wrapperStyle }}
            >
                {content}
                {(selectedObj || val) && (
                    <div
                        className="dna-search-dd-clear"
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()

                            setVal(clearValue || "")
                            inputRef.current.value = clearValue || ""
                            onClear()
                        }}
                    >
                        <Tip
                            followCursor
                            position="right"
                            red={clearTip}
                            title={clearTip || "Clear field..."}
                            noFlip={true}
                        >
                            <IoCloseOutline />
                        </Tip>
                    </div>
                )}
            </div>
        )
    }
}

export default SearchDropDown
