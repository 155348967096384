import React, { useState, useCallback, useRef, useEffect } from "react"
import * as DOMPurify from "dompurify"
import { getMentionName } from "./MentionUtils"
import { getRefId, breakLines, getPersonName } from "../utils/utils"
import { formatMissionPeople } from "../comp/MissionUtils"
import uniq from "lodash/uniq"
import { isBefore } from "date-fns/isBefore"
import RoundAvatar from "./RoundAvatar"
import ReactDOM from "react-dom"
import SimpleBarReact from "simplebar-react"
import { getFileUrl } from "../services/file/file.utils"
import { MentionsInput, Mention } from "react-mentions"
import apiComments from "../services/comment/comment.api"
import apiActionItem from "../services/actionItem/actionItem.api"
import cx from "classnames"
import formatDistance from "date-fns/formatDistance"
import { motion, AnimatePresence } from "framer-motion"
import { toZonedTime } from "date-fns-tz"
import { VscSend } from "react-icons/vsc"
import { PiWechatLogoDuotone } from "react-icons/pi"
import "./ActionItem.scss"

const Messages = ({ ai, app, showComments, orgData, comments, mission, topic, permissions }) => {
    const mentionRef = useRef()
    const commentCount = useRef(comments?.length || 0)

    const sbRef = useRef()
    const oldShowComments = useRef()

    const meInMission = mission.people.find((p) => getRefId(p) === app.state.person._id)

    useEffect(() => {
        if (comments.length > commentCount.current) {
            const scrollBehavior = showComments && !oldShowComments.current ? undefined : "smooth"
            sbRef.current?.getScrollElement().scrollTo({
                top: Number.MAX_SAFE_INTEGER, // Effectively scrolls to the bottom
                behavior: scrollBehavior,
            })
        }

        oldShowComments.current = showComments
        commentCount.current = comments.length
    }, [ai._id, comments.length, topic])

    const [myMessage, setMyMessage] = useState("")

    useEffect(() => {
        adjustTextareaHeight()
    }, [myMessage])

    const adjustTextareaHeight = () => {
        const textarea = mentionRef.current
        if (!textarea) return
        if (textarea && textarea?.value?.length) {
            textarea.style.height = "auto"
            textarea.style.height = textarea?.scrollHeight + "px"
        } else {
            textarea.style.height = 75 + "px"
        }
    }

    const attachPerson = (refId, topic) => {
        const cl = topic ? ai.checklist?.find((c) => c._id === topic) : null

        if (cl && !cl.people?.find((cp) => cp === refId)) {
            let newPeople = (cl?.people || []).slice()
            newPeople.push(refId)
            if (cl.person) {
                newPeople.push(cl.person)
            }
            app.checklistItemUpdateX(ai, cl, {
                people: newPeople,
            })
        }

        setTimeout(() => {
            if (!ai.people.find((ap) => ap === refId)) {
                app.actionItemAddPerson(ai, refId)
            }
        }, 100)
    }

    const send = () => {
        let processedMessage = myMessage //.split("\n").join("<br/>")

        const peopleMentionsMap = mission?.people
            .filter((p) => !!p && p.ref && p.permission <= 3)
            .map((p) => {
                return {
                    mName: getMentionName(p.ref.firstName, p.ref.lastName),
                    _id: getRefId(p),
                }
            })

        let mentions = mission?.people
            .filter((person) => {
                return (
                    getRefId(person) !== app.state.person._id &&
                    (myMessage.includes(`@[${getMentionName(person.ref?.firstName, person.ref?.lastName)}]@`) ||
                        myMessage.includes(`@${getMentionName(person.ref?.firstName, person.ref?.lastName)}`))
                )
            })
            .map((person) => getRefId(person))
            .filter((id) => id !== app.state.person._id)

        peopleMentionsMap.forEach((p, i) => {
            if (
                (myMessage.includes(`@${p.mName}`) || myMessage.includes(`@[${p.mName}]@`)) &&
                !mentions.includes(p._id)
            ) {
                mentions.push(p._id)
            }
        })

        let saveObj = {
            text: processedMessage.replaceAll("@[", "@").replaceAll("]@", ""),
            mentions: uniq(mentions),
        }

        if (topic) {
            saveObj.topic = topic
        }

        saveObj.mentions = saveObj.mentions.filter((id) => id !== app.state.person._id)

        apiComments.actionItem(ai._id).addComment(saveObj)

        mentions.forEach((rId, i) => {
            setTimeout(() => {
                try {
                    const personInMission = mission.people.find((p) => getRefId(p) === rId)
                    if (personInMission?.permission < 3) {
                        attachPerson(rId, topic)
                    }
                } catch (e) {}
            }, (i + 1) * 1000)
        })

        setMyMessage("")
    }

    const onDelete = (comment) => {
        apiComments.deleteComment(comment._id)
    }
    const onReply = (comment, person) => {
        mentionRef.current?.focus()

        const newMsg = `@[${person.ref.firstName} ${person.ref.lastName.charAt(
            0
        )}.]@ Replying to "${comment.text.substring(0, 80)}"...\n`

        setMyMessage(newMsg)
    }

    const peopleTags = mission?.people
        .filter((p) => {
            return p?.ref && !p.invitePending && p.permission <= 3 && getRefId(p) !== app?.state.person._id
        })
        .map((p) => {
            return {
                id: getRefId(p),
                display: getMentionName(p.ref.firstName, p.ref.lastName),
                person: p,
            }
        })

    const addImage = async ({ file }) => {
        const res = await apiActionItem.uploadFiles(ai._id, file, {
            onUploadProgress: (loaded, total) => {},
        })

        app.updateStateForActionItemUpdate(ai, {
            files: res,
        })

        return {
            url: getFileUrl(res[res.length - 1]),
        }
    }

    const handlePaste = async (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items
        for (let index in items) {
            const item = items[index]
            if (item.kind === "file") {
                const blob = item.getAsFile()
                if (blob.type.startsWith("image/")) {
                    // An image has been pasted

                    const file = new File([blob], "paste-img-" + new Date().getTime(), {
                        type: blob.type, // Optional: could set or override the MIME type
                        lastModified: new Date().getTime(), // Optional: set the last modified time
                    })

                    const fl = await addImage({ file })

                    apiComments.actionItem(ai._id).addComment({
                        text: `<img src="${fl.url}"/>`,
                        topic: topic,
                        mentions: [],
                    })
                }
            }
        }
    }

    const emojis = [
        "👍",
        "👎",
        "❤️",
        "🥋",
        "🙂",
        "🎉",
        "🔥",
        "😔",
        "☠️",
        "🦖",
        "🤔",
        "💡",
        "🌶️",
        "🤣",
        "😠",
        "🤷",
        "💥",
        "👆",
        "👇",
        "🙌",
        "🐳",
        "🙏",
        "💪",
        "🤞",
        "🖐️",
        "👏",
    ]
    let cl, old
    if (topic) {
        cl = ai.checklist?.find((c) => c._id === topic)

        old = isBefore(toZonedTime(cl?.createdAt), new Date("2024-09-15"))
    }

    return (
        <div className={"x-aix-messages" + (showComments ? " open" : "")}>
            {!cl ? (
                <div className="dna-pill-box x-aix-message-header chat">
                    <PiWechatLogoDuotone style={{ transform: "translateY(2px)" }} /> Team chat
                </div>
            ) : (
                <div
                    className={cx("dna-pill-box x-aix-message-header", !cl.color && "no-chat")}
                    style={{ backgroundColor: cl?.color, color: cl?.color ? "#111" : undefined }}
                >
                    <PiWechatLogoDuotone style={{ transform: "translateY(2px)" }} />{" "}
                    {old ? "Subtask chat" : cl ? cl.title : "Subtask chat"}
                </div>
            )}

            <SimpleBarReact className="x-aix-message-bars" ref={sbRef}>
                {showComments && (
                    <Comments
                        comments={comments}
                        orgData={orgData}
                        mission={mission}
                        app={app}
                        onDelete={onDelete}
                        onReply={onReply}
                        ai={ai}
                    />
                )}
            </SimpleBarReact>
            {permissions.canUpdate && (
                <div className="x-aix-emojis">
                    {emojis.map((mji) => {
                        return (
                            <div
                                className="dna-under-con small-con"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()

                                    const t = insertTextAtCursor(mentionRef.current, mji)

                                    setMyMessage(DOMPurify.sanitize(t))
                                    mentionRef.current.focus()
                                }}
                            >
                                {mji}
                            </div>
                        )
                    })}
                </div>
            )}
            {(permissions.canUpdate || meInMission.permission === 3) && (
                <>
                    <div className="x-aix-input-wrapper">
                        <MentionsInput
                            className="x-aix-comment-textarea"
                            placeholder="@someone, leave a comment, or paste an image..."
                            value={myMessage}
                            allowSpaceInQuery={true}
                            singleLine={false}
                            inputRef={mentionRef}
                            onPaste={handlePaste}
                            onChange={(e, v) => {
                                const t = DOMPurify.sanitize(e.target.value)
                                setMyMessage(t)
                            }}
                        >
                            <Mention
                                trigger="@"
                                data={peopleTags}
                                displayTransform={(id, display) => {
                                    return `@${display}`
                                }}
                                markup="@[__display__]@"
                                appendSpaceOnAdd={true}
                                renderSuggestion={(obj) => {
                                    const person = obj.person
                                    let avatar = { ...person, ...person.ref }

                                    delete avatar.ref

                                    return (
                                        <>
                                            <RoundAvatar
                                                noTip
                                                org={orgData}
                                                person={person}
                                                width={20}
                                                style={{ marginRight: 5 }}
                                            />{" "}
                                            <div>
                                                {person.ref?.firstName} {person.ref?.lastName}
                                            </div>
                                        </>
                                    )
                                }}
                            />
                        </MentionsInput>

                        <div
                            className="x-ai-send"
                            onClick={() => {
                                send()
                            }}
                        >
                            <VscSend fontSize={21} />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

const Comments = ({ ai, comments, mission, app, onDelete, onReply }) => {
    const commentsMap = comments.map((comment, i) => {
        let missionPerson,
            person = []

        if (comment.createdBy) person = mission?.people.find((p) => getRefId(p) === comment.createdBy._id)

        if (person) {
            person = { ...person, ...comment.createdBy }

            missionPerson = formatMissionPeople([person])[0]
        }

        return (
            <motion.li
                key={comment._id}
                initial={{
                    opacity: 0,
                    y: 30,
                }}
                transition={{ type: "spring", bounce: 0.55, duration: 1.5, delay: 0.3 }}
                animate={{
                    opacity: 1,
                    y: 0,
                }}
                exit={{ y: 0, opacity: 0, x: 200 }}
            >
                <Comment
                    ai={ai}
                    comment={comment}
                    person={missionPerson || person}
                    app={app}
                    onDelete={() => {
                        onDelete(comment)
                    }}
                    onReply={() => {
                        onReply(comment, missionPerson)
                    }}
                />
            </motion.li>
        )
    })

    return (
        <ul>
            <AnimatePresence initial={false}>{commentsMap}</AnimatePresence>
        </ul>
    )
}

const Comment = ({ comment, person, app, orgData, onDelete, onReply, ai }) => {
    const isMyComment = getRefId(person) === app.state.person._id
    const [showImage, setShowImage] = useState()

    const cn = cx("x-aix-comment", {
        mine: isMyComment,
        img: comment.text.includes("<img src"),
    })

    return (
        <>
            <div
                className={cn}
                onClick={(e) => {
                    if (e.target.tagName === "IMG" && e.target.src) {
                        setShowImage(e.target.src)
                    }
                }}
            >
                <div className="dna-flex x-aim-user">
                    <RoundAvatar noTip org={orgData} person={person} width={33} />{" "}
                    <span>{!isMyComment ? <>{getPersonName(person)}</> : "Me"}</span>
                </div>

                {comment.text.includes("<img src") ? (
                    <p className="x-aim-msg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.text) }} />
                ) : (
                    <p className="x-aim-msg">{breakLines(DOMPurify.sanitize(comment.text))}</p>
                )}

                <div className="x-aim-actions dna-flex">
                    {comment.createdAt && (
                        <div className="dna-light-text">
                            Sent {formatDistance(toZonedTime(new Date()), new Date(toZonedTime(comment.createdAt)))} ago
                        </div>
                    )}
                    {!isMyComment && ai.status !== "done" && (
                        <div className="dna-light-text cursor" onClick={onReply}>
                            Reply
                        </div>
                    )}
                    {isMyComment && ai.status !== "done" && (
                        <div className="dna-light-text cursor" onClick={onDelete}>
                            Delete
                        </div>
                    )}
                </div>
            </div>
            {Boolean(showImage) &&
                ReactDOM.createPortal(
                    <div
                        className="x-aix-img-msg-view"
                        onClick={() => {
                            setShowImage(null)
                        }}
                    >
                        <img src={showImage} />
                    </div>,
                    document.body
                )}
        </>
    )
}

function insertTextAtCursor(el, text) {
    var val = el.value,
        endIndex

    let newText = val
    if (typeof el.selectionStart != "undefined" && typeof el.selectionEnd != "undefined") {
        endIndex = el.selectionEnd
        newText = val.slice(0, el.selectionStart) + text + val.slice(endIndex)
    }

    return newText
}

export default Messages
