import { FILE_TYPE, FILE_TYPE_BY_EXTENSION_MAP } from "./file.const"

let streamAbortControllers = []

/**
 * @param objectUrl {String} File url created by URL.createObjectURL() from blob.
 * @param [fileName] {String} Optionally the downloaded file name. Defaults to url path name.
 */
export function downloadFile(objectUrl, fileName) {
    const fileType = getFileTypeFromPath(fileName)
    const link = document.createElement("a")
    link.href = objectUrl
    link.download = fileName || window.location.pathname.split("/").pop()
    if (fileType === FILE_TYPE.IMAGE) {
        // To avoid images replacing the current window (browsers ignore download)
        link.target = "_blank"
    }
    link.click()
}

/**
 * @param file {Object} File document
 */
export function getFileStream(file) {
    const streamSaver = window?.streamSaver
    const stream = streamSaver?.createWriteStream(file.name, {
        size: file.size,
    })
    streamAbortControllers?.push(stream)
    return stream
}

/**
 * @param fileStream {Object} StreamSaver fileStream
 */
export function getFileStreamWriter(fileStream) {
    const writer = fileStream.getWriter()
    streamAbortControllers.push(writer)
    return writer
}

export function abortFileStreams() {
    streamAbortControllers.forEach((controller) => {
        if (controller && controller.abort && !controller.locked) {
            controller.abort()
        }
    })
    streamAbortControllers = []
}

export function addToAbortControllers(controller) {
    streamAbortControllers.push(controller)
}

/**
 * @param path {String} Full file path
 * @return {String} E.g. IMG_5020.jpg
 */
export function getFileNameFromPath(path) {
    return typeof path === "string" ? path.split("/").pop() : path
}

/**
 * @param path {String} Full file path
 * @return {String} E.g. jpg, mov, etc...
 */
export function getFileExtensionFromPath(path) {
    const fileName = getFileNameFromPath(path)
    return typeof fileName === "string" ? path.split(".").pop() : null
}

/**
 * @param path {String} Full file path
 * @return {String} E.g. image, video, audio, etc...
 */
export function getFileTypeFromPath(path) {
    const extension = getFileExtensionFromPath(path)
    const predefinedType = FILE_TYPE_BY_EXTENSION_MAP.get(
        typeof extension === "string" ? extension.toLowerCase() : extension
    )
    return predefinedType ? predefinedType : extension
}

export function getFileMetadata(file) {
    const fileType = getFileTypeFromPath(file.name)
    switch (fileType) {
        case FILE_TYPE.VIDEO:
            return getFileMetadataVideo(file)
        default:
            return {}
    }
}

export function getFileMetadataVideo(file) {
    return new Promise((resolve) => {
        try {
            const video = document.createElement("video")
            video.preload = "metadata"

            video.onloadedmetadata = () => {
                URL.revokeObjectURL(video.src)
                resolve({
                    duration: video.duration,
                })
            }

            video.src = file.source === "external" ? file.path : URL.createObjectURL(file)
        } catch (err) {
            resolve({})
        }
    })
}

/**
 * @param base64Data {String} A base64 data URL
 * @param [fileName] {String} Optional, the name of the file.
 * @return {File}
 */
export function base64DataToFile(base64Data, fileName) {
    try {
        let arr = base64Data.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        if (!fileName) {
            fileName = Math.random().toString(36).substr(2, 5)

            if (mime && mime.includes("/")) {
                fileName = `fileName.${mime.split("/")[1]}`
            }
        }

        return new File([u8arr], fileName, {
            type: mime,
        })
    } catch (err) {
        // Nothing...?
    }
}
