import flatMap from "lodash/flatMap"
import { endOfDay } from "date-fns/endOfDay"
import startOfDay from "date-fns/startOfDay"
import { fromZonedTime } from "date-fns-tz"
import { isAfter } from "date-fns/isAfter"
import { getRefId, ensureNumber } from "../utils/utils"
import { toZonedTime } from "date-fns-tz"
import addDays from "date-fns/addDays"
import addHours from "date-fns/addHours"
import { eachDayOfInterval } from "date-fns/eachDayOfInterval"
import { isBefore } from "date-fns/isBefore"
import subDays from "date-fns/subDays"
import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import isSameWeek from "date-fns/isSameWeek"
import addWeeks from "date-fns/addWeeks"
import differenceInWeeks from "date-fns/differenceInWeeks"
import isSameISOWeek from "date-fns/isSameISOWeek"
import subWeeks from "date-fns/subWeeks"
import { startOfISOWeek } from "date-fns/startOfISOWeek"
import { endOfISOWeek } from "date-fns/endOfISOWeek"

import subMonths from "date-fns/subMonths"
import addMonths from "date-fns/addMonths"
import { isWithinInterval } from "date-fns/isWithinInterval"
import { isSameDay } from "date-fns/isSameDay"
import getISODay from "date-fns/getISODay"
import eachWeekOfInterval from "date-fns/eachWeekOfInterval"
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping"
import differenceInDays from "date-fns/differenceInDays"

export function getNextWorkingDay(date) {
    const nextDay = new Date(date)
    nextDay.setDate(nextDay.getDate() + 1)
    nextDay.setHours(0, 0, 0, 0)
    // Skip weekends
    while (nextDay.getDay() === 6 || nextDay.getDay() === 0) {
        // 6 = Saturday, 0 = Sunday
        nextDay.setDate(nextDay.getDate() + 1)
    }
    return nextDay
}

export const formatDaysAndHoursFromHours = (h) => {
    const dv = Math.floor((h || 0) / 8)
    const days = h ? dv : null
    const hours = h ? h % 8 : null

    let st = ""

    if (days) {
        st = days + " day" + (days === 1 ? "" : "s")
    }
    if (hours) {
        st = st + " " + hours + " hour" + (hours === 1 ? "" : "s")
    }

    return st
}

export function isDateValid(date) {
    return date instanceof Date && !isNaN(date.getTime())
}

export function dateValidationPass({ obj, startDate, endDate }) {
    if (!startDate && !endDate) return true
    let std = startDate || obj.startDate
    let end = endDate || obj.endDate
    if (std >= end) {
        return false
    } else {
        return true
    }
}

export function getStaleDate() {
    return getTimeUtc(subWeeks(new Date(), 7))
}
export function getCompleteBufferDate() {
    return getTimeUtc(subWeeks(new Date(), 1))
}

export function formatRateTimeUnits(unit) {
    switch (unit) {
        case "Monthly":
            return "p/m"

        case "Hourly":
            return "p/h"

        case "Daily":
            return "p/d"

        case "Weekly":
            return "p/w"

        case "One time":
            return "/1x"

        case "Yearly":
            return "p/y"

        default:
    }
}

export function getDateWithinMission({ date, mission }) {
    const start = toZonedTime(mission.planStartDate)
    const end = toZonedTime(mission.planEndDate)

    if (
        isWithinInterval(date, {
            start: start,
            end: end,
        }) ||
        isSameDay(date, start) ||
        isSameDay(date, end)
    ) {
        return date
    }

    if (isBefore(date, start)) {
        return start
    }
    if (isAfter(date, end)) {
        return end
    }

    return date
}

export function getTimeUtc(date) {
    try {
        const utcDate = fromZonedTime(date)
        return utcDate.getTime()
    } catch (err) {
        return undefined
    }
}

export function getStartHour(date) {
    return getISODay(date) * 8 - 8
}

export function getHoursNeeded(planItem) {
    if (!planItem) {
        return 0
    }
    if (planItem.hoursNeeded) {
        return planItem.hoursNeeded
    } else {
        return (differenceInBusinessDays(toZonedTime(planItem.endDate), toZonedTime(planItem.startDate)) + 1) * 8
    }
}
export function getMissionDateRange(mission) {
    let start = getSmallestStartDate(mission.planItems)
    let end = getGreatestEndDate(mission.planItems)

    if (!start || isBefore(toZonedTime(mission.planStartDate), start)) {
        start = toZonedTime(mission.planStartDate)
    }
    if (!end || isBefore(end, toZonedTime(mission.planEndDate))) {
        end = toZonedTime(mission.planEndDate)
    }

    return {
        start: start,
        end: end,
    }
}

export function deriveStartDateAndTime(ai) {
    const start = typeof ai.startDate === "number" ? toZonedTime(ai.startDate) : ai.startDate

    const addr = ai.startHour ? ai.startHour % 8 : 0

    //

    return addHours(start, 9 + addr)
}

export function calculateDateDifference(startDate, endDate) {
    // Convert input strings to Date objects
    const start = new Date(startDate)
    const end = new Date(endDate)

    // Calculate total differences
    let years = end.getFullYear() - start.getFullYear()
    let months = end.getMonth() - start.getMonth()
    let days = end.getDate() - start.getDate()

    // Adjust for negative days
    if (days < 0) {
        months--
        days += new Date(end.getFullYear(), end.getMonth(), 0).getDate()
    }

    // Adjust for negative months
    if (months < 0) {
        years--
        months += 12
    }

    // Create result string
    const yearString = years > 0 ? `${years} year${years > 1 ? "s" : ""}` : ""
    const monthString = months > 0 ? `${months} month${months > 1 ? "s" : ""}` : ""
    const dayString = days > 0 ? `${days} day${days > 1 ? "s" : ""}` : ""

    // Concatenate parts with appropriate commas and "and"
    const parts = [yearString, monthString, dayString].filter((part) => part !== "")
    const result =
        parts.length > 1 ? parts.slice(0, -1).join(", ") + " and " + parts[parts.length - 1] : parts[0] || "0 days"

    return result
}

export function deriveEndDateAndTime(ai) {
    const start = typeof ai.startDate === "number" ? toZonedTime(ai.startDate) : ai.startDate

    if ((ai.startHour === null || ai.startHour === undefined) && ai.endDate) {
        return toZonedTime(ai.endDate)
    }

    if ((ai.startHour !== 0 && ai.startHour === null) || ai.startHour === undefined) {
        console.error("no startHour provided")
        return
    }

    const dur = ai.estimatedDuration || ai.duration

    if (!dur) {
        return null
    }

    let eod = dur / 8

    if (eod < 1) {
        eod = 1
    }

    let endDate

    if (ai.startHour % 8 === 0 && dur % 8 === 0) {
        endDate = endOfDay(addDays(start, eod - 1))
    } else {
        const myStartDate = startOfDay(deriveStartDateAndTime(ai))

        //Basic end date 00:00
        const howFarInAnEightHourDayAmIStarting = ai.startHour % 8

        const bleedToNextDay = (dur + howFarInAnEightHourDayAmIStarting) / 8

        const daysToAddToStartDate =
            (dur + howFarInAnEightHourDayAmIStarting) % 8 === 0 ? dur / 8 : Math.floor(bleedToNextDay)
        //End of day check

        const whatIsMyEndDateJustByEstimatedDuration = addDays(myStartDate, daysToAddToStartDate)

        let hoursToCover = 24

        try {
            hoursToCover = eachDayOfInterval({ start: start, end: whatIsMyEndDateJustByEstimatedDuration }).length * 8
        } catch (err) {}

        //What hours in the last day do I end?

        const leftOverHoursToDistribute = hoursToCover - dur
        const howManyGoToStart = howFarInAnEightHourDayAmIStarting
        const howManyGoToEnd = 8 - (leftOverHoursToDistribute - howManyGoToStart)

        const finalHoursToAdd = howManyGoToEnd + 9

        const myEndDateWithWorkDayTime =
            finalHoursToAdd === 17
                ? endOfDay(whatIsMyEndDateJustByEstimatedDuration)
                : addHours(whatIsMyEndDateJustByEstimatedDuration, finalHoursToAdd)

        endDate = myEndDateWithWorkDayTime
    }

    return endDate
}

export function findAiEndDate(ai) {
    if (ai.endDate) {
        return toZonedTime(ai.endDate)
    }

    if (ai.dueDate && (!ai.startDate || ai.startDate < ai.dueDate)) {
        return endOfDay(toZonedTime(ai.dueDate))
    }
    if (!ai.startDate && ai.completedOn) {
        return toZonedTime(ai.completedOn)
    }

    if (ai.week) {
        const startDay = addDays(toZonedTime(ai.week), Math.floor(ensureNumber(ai.startHour) / 8))
        const days = ai.estimatedDuration ? Math.ceil(ai.estimatedDuration / 8) - 1 : 1
        const end = endOfDay(addDays(startDay, days))

        return end
    }

    if (ai.startDate && ai.startHour >= 0 && ai.startHour !== null && ai.estimatedDuration > 0) {
        const days = ai.estimatedDuration / 8 - 1

        return endOfDay(addDays(toZonedTime(ai.startDate), days))
    }

    return null
}

export function getGreatestEndDate(list) {
    const sortedItems = list.sort((a, b) => a.endDate - b.endDate).reverse()

    if (sortedItems[0]?.endDate) {
        return endOfDay(toZonedTime(sortedItems[0].endDate))
    } else {
        return null
    }
}
export function getSmallestStartDate(list) {
    const sortedItems = list.sort((a, b) => a.startDate - b.startDate)

    if (sortedItems[0]?.startDate) {
        return startOfDay(toZonedTime(sortedItems[0].startDate))
    } else {
        return null
    }
}

export function getMyMissingTimesheets({ person, missions, myTimesheets, theStartDate }) {
    if (!theStartDate) theStartDate = startOfISOWeek(subMonths(new Date(), 6))

    const myRoles = flatMap(
        missions.filter((m) => !m.noTimesheets),
        "planItems"
    )
        .filter((r) => r.type === "person")
        .filter((r) => {
            const foundRole = r.person === getRefId(person) || r.personLeftRole?.personId === getRefId(person)

            return foundRole
        })

    if (myRoles.length === 0) {
        return []
    }
    //hi
    const tsItems = myTimesheets.map((t) => ({ startDate: t.weekStart, endDate: t.weekEnd }))

    let planStart = subMonths(getSmallestStartDate(myRoles), 6)
    let planEnd = addMonths(getGreatestEndDate(myRoles), 6)

    const tsStart = subMonths(getSmallestStartDate(tsItems), 6)
    const tsEnd = addMonths(getGreatestEndDate(tsItems), 6)

    const smallestDate = isAfter(planStart, tsStart) ? tsStart : planStart
    const greatestEndDate = isAfter(tsEnd, planEnd) ? tsEnd : planEnd

    let missingMissionTimesheets = []

    //Roles first

    const roleStart = smallestDate
    const roleEnd = greatestEndDate

    const roleWeeks =
        !roleStart || isAfter(roleStart, new Date())
            ? []
            : eachWeekOfInterval(
                  {
                      start: roleStart,
                      end: new Date(),
                  },
                  {
                      weekStartsOn: 1,
                  }
              )

    roleWeeks.forEach((week, i) => {
        if (isAfter(week, new Date())) {
            return
        }
        let overLappingRoles = myRoles.filter((r) => {
            //What roles overlap any of the weeks from start to this week
            const std = toZonedTime(r.startDate)
            const end = toZonedTime(r.endDate)

            let isIn = false

            try {
                isIn = areIntervalsOverlapping(
                    {
                        start: std,
                        end: end,
                    },
                    {
                        start: week,
                        end: endOfISOWeek(week),
                    }
                )
            } catch (e) {}

            return isIn
        })

        overLappingRoles.forEach((role, i) => {
            const foundTimesheet = myTimesheets.find((tsr) => {
                return (
                    (tsr.snapshot?.planItemId || tsr.planItemId) === role._id &&
                    isSameWeek(week, toZonedTime(tsr.weekStart), { weekStartsOn: 1 })
                )
            })

            const isItMissing = !foundTimesheet

            if (isItMissing) {
                const mission = missions.find((m) => m._id === role.missionId)

                missingMissionTimesheets.push({
                    weekStart: getTimeUtc(week),
                    weekEnd: getTimeUtc(endOfISOWeek(week)),
                    isMissing: true,
                    planItem: role,
                    mission: mission,
                    person: getRefId(person),
                    snapshot: {
                        planItem: role,
                        mission: mission,
                        person: person,
                    },
                })
            }
        })
    })

    return missingMissionTimesheets
}

export function getDisplayUnits(planItem) {
    if (planItem.durationNeededDisplayUnit === "Hours" || !planItem.durationNeededDisplayUnit) {
        return planItem.hoursNeeded
    } else if (planItem.durationNeededDisplayUnit === "Days") {
        return planItem.hoursNeeded / 8
    } else {
        return planItem.hoursNeeded / 8 / 5
    }
}

export function changeSimpleKanbanEndDate({ mission, app, date }) {
    const doChange = () => {
        const diffInDays = differenceInDays(date, toZonedTime(mission.planEndDate))

        if (diffInDays > 0) {
            app.missionUpdate(mission._id, {
                planEndDate: getTimeUtc(endOfDay(date)),
            })
        } else {
            app.missionUpdate(
                mission._id,
                {
                    planEndDate: getTimeUtc(endOfDay(date)),
                },
                () => {
                    let newSectionTags = mission.sectionTags.slice()

                    mission.sectionTags.forEach((st, i) => {
                        const std = toZonedTime(st.from)
                        const end = toZonedTime(st.to)
                        if (isAfter(end, endOfDay(date))) {
                            let newStart = newSectionTags[i].from
                            let newEnd = newSectionTags[i].to
                            const days =
                                eachDayOfInterval({
                                    start: endOfDay(date),
                                    end: end,
                                }).length - 1

                            newStart = subDays(std, days)
                            newEnd = endOfDay(date)

                            if (isAfter(newStart, newEnd)) {
                                newStart = startOfDay(date)
                            }

                            if (isAfter(toZonedTime(mission.planStartDate), newStart)) {
                                newStart = mission.planStartDate
                            }

                            newSectionTags[i].from = getTimeUtc(newStart)
                            newSectionTags[i].to = getTimeUtc(newEnd)
                        }
                    })

                    app.missionUpdate(
                        mission._id,
                        {
                            sectionTags: newSectionTags,
                        },
                        () => {
                            const aiChanges = mission.planItems
                                .flatMap((p) => p.actions)
                                .flatMap((a) => a.actionItems)
                                .map((ai) => {
                                    let changeNeeded = false
                                    let change = {
                                        _id: ai._id,
                                    }

                                    if (ai.endDate && isAfter(toZonedTime(ai.endDate), endOfDay(date))) {
                                        changeNeeded = true
                                        change.endDate = null
                                    }
                                    if (ai.week && isAfter(toZonedTime(ai.week), endOfDay(date))) {
                                        changeNeeded = true
                                        change.week = null
                                        change.startDate = null
                                    }
                                    if (changeNeeded)
                                        return {
                                            obj: ai,
                                            change: change,
                                        }

                                    return null
                                })
                                .filter((c) => !!c)

                            if (aiChanges.length) {
                                app.actionItemUpdateBulk(mission._id, aiChanges)
                            }
                        }
                    )
                }
            )
        }
    }
    const currentWeeks = eachWeekOfInterval(
        {
            start: toZonedTime(mission.planStartDate),
            end: toZonedTime(mission.planEndDate),
        },
        { weekStartsOn: 1 }
    ).length

    const newWeeks = eachWeekOfInterval(
        {
            start: toZonedTime(mission.planStartDate),
            end: endOfDay(date),
        },
        { weekStartsOn: 1 }
    ).length

    if (currentWeeks > newWeeks) {
        app.confirm({
            severe: true,
            comp: (
                <>
                    <p>
                        This cannot be undone and will compress your timeline and change the dates of any plan items
                        like tasks, roles, milestones that fall outside of the new date.
                    </p>
                    <p>
                        If any tasks are planned for after the new end date they will be sent back to your planning
                        board.
                    </p>
                </>
            ),
            yesText: "Make the changes",
            yesColor: "red",
            onYes: () => {
                doChange()
            },
        })
    } else {
        doChange()
    }
}

export function changeSimpleKanbanStartDate({ mission, app, date, shift, cb }) {
    const shiftWeeks = differenceInWeeks(date, toZonedTime(mission.planStartDate))

    const mainAction = mission.planItems
        .flatMap((p) => p.actions)
        .find((a) => a.source === "mx-ai" || a.aiSource === "mx-ai")

    const allActionItems = mission.planItems.flatMap((p) => p.actions).flatMap((a) => a.actionItems)

    app.block()

    let actionItemChangeArray = []

    const originalStartDate = toZonedTime(mission.planStartDate)
    const originalEndDate = toZonedTime(mission.planEndDate)

    if (!shift) {
        debugger
        if (shiftWeeks === 0) {
            debugger
            app.missionUpdate(
                mission._id,
                {
                    planStartDate: getTimeUtc(startOfDay(date)),
                },
                () => {
                    debugger
                    if (isAfter(date, toZonedTime(toZonedTime(mission.planStartDate)))) {
                        const newStartHour = getStartHour(date)

                        allActionItems.forEach((ai, i) => {
                            const sameWeekPass = isSameISOWeek(toZonedTime(ai.week), date)

                            if (sameWeekPass && newStartHour > ai.startHour)
                                actionItemChangeArray.push({
                                    obj: ai,
                                    change: {
                                        _id: ai._id,
                                        startHour: newStartHour,
                                        estimatedDuration:
                                            newStartHour + ai.estimatedDuration > 56
                                                ? 56 - newStartHour
                                                : ai.estimatedDuration,
                                    },
                                })
                        })
                    }

                    if (mainAction) {
                        app.actionUpdateX(mainAction, { startDate: getTimeUtc(startOfDay(date)) })
                    }

                    if (actionItemChangeArray.length) {
                        setTimeout(() => {
                            app.actionItemUpdateBulk(mission._id, actionItemChangeArray)
                        }, 1000)
                    }

                    setTimeout(() => {
                        app.unBlock()
                        if (cb) cb()
                    }, 1000)
                }
            )
        } else if (shiftWeeks < 0) {
            app.missionUpdate(
                mission._id,
                {
                    planStartDate: getTimeUtc(startOfDay(date)),
                },
                () => {
                    app.unBlock()
                }
            )
        } else if (shiftWeeks > 0) {
            app.missionUpdate(
                mission._id,
                {
                    planStartDate: getTimeUtc(startOfDay(date)),
                },
                () => {
                    allActionItems.forEach((ai, i) => {
                        const updateNeeded = isBefore(toZonedTime(ai.week), startOfISOWeek(date))

                        if (updateNeeded) {
                            const newWeek = startOfISOWeek(date)
                            actionItemChangeArray.push({
                                obj: ai,
                                change: {
                                    _id: ai._id,
                                    week: getTimeUtc(newWeek),
                                },
                            })
                        }
                    })

                    if (mainAction) {
                        app.actionUpdateX(mainAction, { startDate: getTimeUtc(startOfDay(date)) })
                    }

                    if (actionItemChangeArray.length) {
                        setTimeout(() => {
                            app.actionItemUpdateBulk(mission._id, actionItemChangeArray)
                        }, 500)
                    }

                    setTimeout(() => {
                        app.unBlock()
                        if (cb) cb()
                    }, 1000)
                }
            )
        }
    } else {
        const shiftAmount = differenceInDays(date, toZonedTime(mission.planStartDate))

        const newEndDate = endOfDay(addDays(toZonedTime(mission.planEndDate), shiftAmount))

        app.missionUpdate(
            mission._id,
            {
                planStartDate: getTimeUtc(startOfDay(date)),
                planEndDate: getTimeUtc(newEndDate),
            },
            () => {
                allActionItems.forEach((ai, i) => {
                    if (ai.week) {
                        const newWeek = ai.week ? startOfISOWeek(addDays(toZonedTime(ai.week), shiftAmount)) : null
                        const newAiEndDate = ai.endDate ? addWeeks(toZonedTime(ai.endDate), shiftAmount / 7) : null

                        actionItemChangeArray.push({
                            obj: ai,
                            change: {
                                _id: ai._id,
                                week: newWeek ? getTimeUtc(newWeek) : null,
                                endDate: newAiEndDate ? getTimeUtc(newAiEndDate) : null,
                            },
                        })
                    }
                })

                if (mainAction) {
                    app.actionUpdateX(mainAction, {
                        startDate: getTimeUtc(startOfDay(date)),
                        endDate: getTimeUtc(newEndDate),
                    })
                }

                if (actionItemChangeArray.length) {
                    setTimeout(() => {
                        app.actionItemUpdateBulk(mission._id, actionItemChangeArray)
                    }, 500)
                }

                let outcomeChanges = []

                mission.planItems
                    .filter((p) => p.type === "milestone")
                    .forEach((ms, i) => {
                        const newWeek = ms.startDate ? addWeeks(toZonedTime(ms.startDate), shiftWeeks) : null

                        outcomeChanges.push({
                            obj: ms,
                            change: {
                                _id: ms._id,
                                startDate: newWeek ? getTimeUtc(newWeek) : null,
                                endDate: newWeek ? getTimeUtc(endOfDay(newWeek)) : null,
                            },
                        })
                    })

                if (outcomeChanges.length) {
                    app.planItemUpdateBulk(mission._id, outcomeChanges)
                }

                setTimeout(() => {
                    app.unBlock()
                    if (cb) cb()
                }, 1000)
            }
        )
    }

    //Change planItem Date

    //change action date

    //change misison date

    //comit actionItems bulk
}

export function changeRoleDate({ planItem, change, app }) {}
