import flatMap from "lodash/flatMap"
import subWeeks from "date-fns/subWeeks"
import { endOfISOWeek } from "date-fns/endOfISOWeek"
import { getObjectId } from "../utils/utils"
import { getTimesheetHours } from "../comp/DateUtils"
import isSameISOWeek from "date-fns/isSameISOWeek"
import { getTimeUtc } from "../utils/dates"
import eachWeekOfInterval from "date-fns/eachWeekOfInterval"
import { toZonedTime } from "date-fns-tz"
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping"
import { startOfISOWeek } from "date-fns/startOfISOWeek"

import apiTimesheet from "../services/timesheet/timesheet.api"

export function piHasTimesheets(pi, timesheets) {
    if (timesheets.length)
        return timesheets.find((t) => t.planItemId === pi._id && (t.status === "submitted" || t.status === "approved"))

    if (pi.timesheets) {
        return pi.timesheets.length
    }

    return false
}

export async function getMissionTimesheets(mission) {
    const t = await apiTimesheet.getMissionTimesheet(mission?._id)

    return t
}

export function updateStateTimesheets({ timesheets = [], how, data }) {
    let newTimesheets = timesheets.slice()
    switch (how) {
        case "add":
        case "push":
            newTimesheets.push(data)
            break

        case "merge":
            newTimesheets = [...timesheets, ...data]
            break
        case "delete":
            const existinIndex = newTimesheets.findIndex((ts) => ts._id === data._id)
            if (existinIndex !== -1) {
                newTimesheets.splice(existinIndex, 1)
            }
            break

        case "update":
            const existinIndex2 = newTimesheets.findIndex((ts) => ts._id === data._id)

            if (existinIndex2 !== -1) {
                newTimesheets[existinIndex2] = { ...newTimesheets[existinIndex2], ...data }
            }
            break
        case "set":
            newTimesheets = data
            break
        default:
    }

    return newTimesheets
}

export const getMissingTimesheets = async (app) => {
    let timesheets = []

    const st = startOfISOWeek(subWeeks(new Date(), 3))
    const ed = endOfISOWeek(subWeeks(new Date(), 1))

    try {
        timesheets = await apiTimesheet.getMyTimesheets({
            weekStart: getTimeUtc(st),
            weekEnd: getTimeUtc(ed),
        })
    } catch (e) {
        app.silentFail("elkrwm3ssio" + e)
        return []
    }

    timesheets = timesheets.filter((t) => !t.org || (t.org && getTimesheetHours(t) > 0))

    const roles = flatMap(
        app.state.missions.filter((m) => !m.noTimesheets && m.isLicensedActive),
        "planItems"
    ).filter((p) => {
        const init =
            p.person === app.state.person._id &&
            !p.inactive &&
            areIntervalsOverlapping(
                {
                    start: st,
                    end: ed,
                },
                {
                    start: toZonedTime(p.startDate),
                    end: toZonedTime(p.endDate),
                }
            )

        return init
    })

    let missing = []

    eachWeekOfInterval(
        {
            start: st,
            end: ed,
        },
        { weekStartsOn: 1 }
    ).forEach((week, i) => {
        roles.forEach((pi, i) => {
            if (
                !areIntervalsOverlapping(
                    {
                        start: week,
                        end: endOfISOWeek(week),
                    },
                    {
                        start: toZonedTime(pi.startDate),
                        end: toZonedTime(pi.endDate),
                    }
                )
            ) {
                return
            }

            const foundTimesheet = timesheets?.find(
                (t) => t.planItemId === pi._id && isSameISOWeek(toZonedTime(t.weekStart), week)
            )

            if (!foundTimesheet || foundTimesheet?.status === "draft" || foundTimesheet?.status === "rejected") {
                if (foundTimesheet) {
                    const appMission = app.state.missions.find(
                        (m) => getObjectId(m) === getObjectId(foundTimesheet.mission)
                    )

                    if (!appMission) {
                        return
                    }

                    /*const orgData = app.state.orgs.find((m) => getObjectId(m) === getObjectId(appMission.org))
                    const role = orgData.roles.find((r) => r._id === pi.role)?.name || pi.role || pi.title
                    console.log("*****************************************************")
                    console.log(week, toZonedTime(pi.startDate), toZonedTime(pi.endDate))
                    console.log("planItem", pi._id, appMission?.title, role, pi.personLeftRole, pi)*/
                } else {
                    /*console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++")
                    console.log(week, toZonedTime(pi.startDate), toZonedTime(pi.endDate))*/
                }

                missing.push(week)
            }
        })
    })

    return missing
}
