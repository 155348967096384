import React from "react"
import Autocomplete from "react-autocomplete"
import cx from "classnames"
import { IoChevronDownOutline } from "react-icons/io5"

class LimitedAutocomplete extends Autocomplete {
    getFilteredItems(props) {
        const items = Autocomplete.prototype.getFilteredItems.call(this, props)
        return items.slice(0, props.maxItems)
    }
}

export default class DropDown extends React.PureComponent {
    static defaultProps: {
        data: [],
    }

    constructor(props) {
        super(props)

        this.domContainer = React.createRef()

        this.state = {}
    }
    componentDidMount() {
        if (this.domContainer.current && this.props.autoFocus) {
            try {
                setTimeout(() => {
                    this?.domContainer?.current?.querySelector("input")?.focus()
                }, 20)
            } catch (e) {}
        }
    }
    onChangeHandler(val, e) {
        if (this.props.onChange) this.props.onChange(val, e)
    }
    onSelect(value, obj) {
        this.setState({ value })

        if (this.props.onSelect) this.props.onSelect(value, obj)
    }
    render() {
        const cn = cx("dna-drop-down", this.props.className, {
            center: this.props.center,
            clear: this.props.clear,
            active: this.state.active,
        })

        const me = this

        let getRef = this.props.getRef

        const addOpen = this.props.open === true ? { open: true } : {}

        const inputProps = { placeHolder: this.props.placeHolder, ...this.props.inputProps }

        return (
            <div className={cn} style={this.props.style} ref={this.domContainer}>
                <LimitedAutocomplete
                    maxItems={this.props.maxItems}
                    autoHighlight={this.props.autoHighlight}
                    getItemValue={this.props.getItemValue || getVal}
                    wrapperStyle={this.props.wrapperStyle}
                    onMenuVisibilityChange={(d) => {
                        this.setState({
                            active: d,
                        })

                        if (this.props.onMenuVisibilityChange) this.props.onMenuVisibilityChange(d)
                    }}
                    ref={getRef}
                    renderInput={(props) => {
                        return <input data-x-dd="true" {...props} autoComplete="new-password" />
                    }}
                    isItemSelectable={this.props.isItemSelectable}
                    inputProps={inputProps}
                    items={this.props.items}
                    isItemClickable={this.props.isItemClickable || true}
                    renderItem={this.props.template ? this.props.template : BasicTemplate}
                    renderMenu={(a, b) => {
                        if (a && a.length) {
                            return (
                                <div className="dna-dd-menu no-blur" style={{ width: this.props.menuWidth }}>
                                    {a}
                                </div>
                            )
                        } else {
                            if (me.props.noEmptyMenu) {
                                return <></>
                            } else {
                                return (
                                    <div className="dna-dd-menu no-blur" style={{ width: this.props.menuWidth }}>
                                        <div className="dna-dd-item dna-light-text">
                                            {this.props.noResultsText || "No results found..."}
                                        </div>
                                    </div>
                                )
                            }
                        }
                    }}
                    value={this.props.value || ""}
                    onChange={(e, value) => {
                        me.onChangeHandler(value, e)
                    }}
                    onSelect={this.onSelect.bind(this)}
                    shouldItemRender={this.props.shouldItemRender}
                    selectOnBlur={this.props.selectOnBlur}
                    {...addOpen}
                />
                <span className="x-dd-dd-dd-icon">
                    <IoChevronDownOutline />
                </span>
            </div>
        )
    }
}

const BasicTemplate = (item, isHighlighted) => {
    return (
        <div key={item.value || item._id || item.id} className={isHighlighted ? "dna-dd-item active" : "dna-dd-item"}>
            {item.label || item}
        </div>
    )
}

const getVal = (item) => (item && item.label ? item.label : item?.title ? item.title : item ? item : "")
