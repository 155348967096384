import React, { useState, useEffect, useRef } from "react"
import "./Board.scss"
import { toast } from "react-toastify"
import { getRefId, isMissionComplete, getObjectId, formatError, useDebouncedEffect } from "../utils/utils"
import { missionWarnings } from "../utils/mission"
import { makeHash } from "../utils/utils"
import Loadable from "react-loadable"
import Badge from "@material-ui/core/Badge"
import { getTimeUtc } from "../utils/dates"
import Bubble from "../comp/Bubble"
import subWeeks from "date-fns/subWeeks"
import { getMissionTimesheets } from "../utils/timesheets"
import addWeeks from "date-fns/addWeeks"
import startOfISOWeek from "date-fns/startOfISOWeek"
import endOfISOWeek from "date-fns/endOfISOWeek"
import Tabs from "../comp/Tabs"
import { toZonedTime } from "date-fns-tz"
import {
    PiClockDuotone,
    PiCreditCardDuotone,
    PiAirplaneDuotone,
    PiCalendarCheckDuotone,
    PiStackDuotone,
    PiAlignLeftDuotone,
    PiRocketDuotone,
    PiFileTextDuotone,
} from "react-icons/pi"
import apiTimesheet from "../services/timesheet/timesheet.api"
import apiExpenses from "../services/expense/expense.api"
import apiPeopleLeave from "../services/peopleLeave/peopleLeave.api"

import { updateStateTimesheets } from "../utils/timesheets"
import { updateStateLeaves } from "../utils/leaves"
import { updateStateExpenses } from "../utils/expenses"
import { removeParam } from "../comp/History"
import groupBy from "lodash/groupBy"
const PmTimesheets = Loadable({
    loader: () => import("../comp/PmTimesheets"),
    loading: () => <span />,
})

const VisionBoard = Loadable({
    loader: () => import("../comp/VisionBoard"),
    loading: () => <span />,
})

const PmLeaves = Loadable({
    loader: () => import("../comp/PmLeaves"),
    loading: () => <span />,
})

const Docs = Loadable({
    loader: () => import("../comp/Docs"),
    loading: () => <span />,
})

const ResourceTimeline = Loadable({
    loader: () => import("../comp/ResourceTimeline"),
    loading: () => <span />,
})

const ProjectPlanner = Loadable({
    loader: () => import("../comp/ProjectPlanner"),
    loading: () => <span />,
})

const ExpenseTracker = Loadable({ loader: () => import("../comp/ExpenseTracker"), loading: () => <span /> })

const Board = ({ app, stateChange }) => {
    const [tabs, setTabs] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const [pmMissions, setPmMissions] = useState([])
    const [licensedPmMissions, setLicensedPmMissions] = useState([])
    const [nonPmMissions, setNonPmMissions] = useState([])
    const [completeMissions, setCompleteMissions] = useState([])
    const [updateKey, setUpdateKey] = useState([])
    const [timesheets, setTimesheets] = useState([])
    const [expenses, setExpenses] = useState([])
    const [leaves, setLeaves] = useState([])
    const [warningData, setWarningData] = useState([])
    const timesheetsRef = useRef([])
    const expensesRef = useRef([])
    const leavesRef = useRef([])

    const changeTab = (indx) => {
        removeParam("board-tab")
        localStorage.setItem("x-board-tab", indx)
        setTabIndex(indx)
    }

    useEffect(() => {
        lastStateChange.current = null
        ranRef.current = null
    }, [app.state.mission, app.state.org])

    useEffect(() => {
        if (licensedPmMissions.length === 0) {
            setTabIndex(0)
            return
        }

        const q = new URLSearchParams(window.location.search)
        if (q.has("board-tab")) {
            const index = tabs.findIndex((t) => t.id === q.get("board-tab"))
            setTabIndex(index === -1 ? 0 : index)
        } else {
            const savedTabIndex = localStorage.getItem("x-board-tab")

            if (+savedTabIndex) {
                setTabIndex(+savedTabIndex)
            }
        }
    }, [licensedPmMissions.length, tabs])

    const lastStateChange = useRef()
    const ranRef = useRef()

    useEffect(() => {
        const passAlong = {
            app,
            stateChange,
            context: "pm",
            getPmLeaves,
            asyncTimesheets,
            asyncExpenses,
            asyncLeaves,
            timesheets,
            leaves,
        }

        let myTabs = [
            {
                title: () => (
                    <>
                        <PiRocketDuotone />
                        {licensedPmMissions.length ? "Missions" : "Launchpad"}
                    </>
                ),
                id: "vision-board",
                comp: VisionBoard,
                notComponent: true,
                params: {
                    ...passAlong,
                    pmMissions: licensedPmMissions,
                    myMissions: nonPmMissions,
                    timesheets,
                },
            },
        ]

        if (licensedPmMissions.length) {
            myTabs = [
                ...myTabs,
                {
                    title: () => (
                        <>
                            <PiAlignLeftDuotone />
                            Planner{" "}
                        </>
                    ),
                    comp: ProjectPlanner,
                    notComponent: true,
                    id: "pm-Planner",
                    params: {
                        missions: licensedPmMissions.filter(
                            (m) => m.projectType === "mx-gantt" && m.kickedOff === true && !m.isModel && !m.isTemplate
                        ),
                        app,
                        timesheets,
                        leaves,
                        stateChange,
                    },
                },
                {
                    title: () => (
                        <Badge
                            badgeContent={timesheets?.filter((ts) => ts.status === "submitted")?.length}
                            color="primary"
                        >
                            <PiClockDuotone />
                            Timesheets
                        </Badge>
                    ),
                    comp: PmTimesheets,
                    notComponent: true,
                    id: "timesheets",
                    params: {
                        ...passAlong,
                        missions: licensedPmMissions,
                        timesheets,
                        timesheetsRef,
                        updateTimesheetsInState,
                        updateKey,
                        context: "multi",
                    },
                },
                {
                    title: () => (
                        <Badge
                            badgeContent={expenses?.filter((ex) => ex.status === "submitted")?.length}
                            color="primary"
                        >
                            <PiCreditCardDuotone />
                            Expenses
                        </Badge>
                    ),
                    comp: ExpenseTracker,
                    id: "expenses",
                    notComponent: true,
                    params: {
                        ...passAlong,
                        missions: licensedPmMissions,
                        context: "pm",
                        givenExpenses: expenses,
                        updateExpensesInState,
                    },
                },
                {
                    title: () => (
                        <Badge
                            badgeContent={
                                leaves?.filter(
                                    (l) =>
                                        l.status === "pending_approval" &&
                                        !l.reviews?.find((r) => r.by === app.state.person._id)
                                ).length
                            }
                            color="primary"
                        >
                            <PiAirplaneDuotone />
                            Leaves
                        </Badge>
                    ),
                    comp: PmLeaves,
                    id: "leaves",
                    notComponent: true,
                    params: {
                        ...passAlong,
                        missions: licensedPmMissions,
                        leaves,
                        updateLeavesInState,
                        asyncLeaves,
                    },
                },
                {
                    title: () => (
                        <>
                            <PiCalendarCheckDuotone />
                            Scheduling
                        </>
                    ),
                    comp: ResourceTimeline,
                    notComponent: true,
                    params: {
                        ...passAlong,
                        missions: licensedPmMissions,
                        missionChange: stateChange,
                        leaveRequests: leaves,
                    },
                },
                {
                    title: () => (
                        <>
                            <PiFileTextDuotone />
                            Mission Docs
                        </>
                    ),
                    comp: Docs,
                    notComponent: true,
                    params: {
                        ...passAlong,
                        app: app,
                        missions: licensedPmMissions,
                        missionChange: stateChange,
                        leaveRequests: leaves,
                    },
                },
            ]
        }

        setTabs(myTabs)
    }, [stateChange?.timestamp, updateKey])

    useEffect(() => {
        let pmM = []
        let teamM = []
        let completeM = []
        app.state.missions.forEach((m, i) => {
            const meInM = m?.people?.find((p) => getRefId(p) === app.state.person._id)

            if (!meInM || m.loadedFromOrg) {
                return
            }

            if (m.isTemplate || meInM.permission > 3) return
            if (isMissionComplete(m)) {
                completeM.push(m)
                return
            }

            if (meInM) {
                if (m.org && (meInM.isProjectManager || meInM.permission === 2)) {
                    pmM.push(m)
                } else {
                    teamM.push(m)
                }
            }
        })

        setNonPmMissions(teamM)
        setPmMissions(pmM.map((m) => ({ ...m, isPm: true })))
        setCompleteMissions(completeM)
        setLicensedPmMissions(pmM.filter((m) => m.isLicensedActive && m.org))

        setUpdateKey(new Date().getTime())
    }, [app.state.missions.length, stateChange.timestamp])

    useEffect(() => {
        app.hideAppAdminTools()

        return () => {
            app.showAppAdminTools()
            removeParam("board-tab")
        }
    }, [])

    useDebouncedEffect(
        () => {
            let warningTimeout, timesheetsTimeout, expensesTimeout, leavesTimeout

            if (licensedPmMissions.length) {
                const wd = missionWarnings({ missions: licensedPmMissions, app })
                setWarningData(wd)
                timesheetsTimeout = setTimeout(() => {
                    try {
                        asyncTimesheets()
                    } catch (e) {}
                }, 400) // Add appropriate delay

                expensesTimeout = setTimeout(() => {
                    asyncExpenses()
                }, 1000) // Add appropriate delay

                asyncLeaves()
            }

            return () => {
                clearTimeout(warningTimeout)
                clearTimeout(timesheetsTimeout)
                clearTimeout(expensesTimeout)
                clearTimeout(leavesTimeout)
            }
        },
        [licensedPmMissions.length],
        300
    )

    const asyncTimesheets = async (force) => {
        let ts = []
        try {
            ts = await getPmTimesheets({ licensedPmMissions, force })
            debugger
            if (ts?.length) updateTimesheetsInState({ how: "set", data: ts })
            debugger
        } catch (err) {
            debugger
            app.silentFail("ts-sss" + err.message)
            app.unBlock()
        }
    }
    const asyncExpenses = async () => {
        const expen = await getPmExpenses({
            app,
            pmMissions: licensedPmMissions.filter((m) => {
                const org = app.state.orgs?.find((o) => o._id === getObjectId(m.org))

                if (org.invoicingDisabled) {
                    return false
                }

                return true
            }),
        })
        updateExpensesInState({ how: "set", data: expen?.filter((ex) => ex.status !== "draft") })
    }
    const asyncLeaves = async (force) => {
        const leav = await getPmLeaves({ app, force })

        updateLeavesInState({ how: "set", data: leav?.filter((lv) => lv.status !== "draft") })
    }
    const updateTimesheetsInState = ({ how, data }) => {
        const newSheets = updateStateTimesheets({
            timesheets: timesheetsRef.current,
            how,
            data,
        })

        setTimesheets(newSheets)
        timesheetsRef.current = newSheets
        setUpdateKey(new Date().getTime())
    }
    const updateExpensesInState = ({ how, data }) => {
        const newExpenses = updateStateExpenses({
            expenses: expensesRef.current,
            how,
            data,
        })

        setExpenses(newExpenses)
        expensesRef.current = newExpenses
        setUpdateKey(new Date().getTime())
    }
    const updateLeavesInState = ({ how, data }) => {
        const newLeaves = updateStateLeaves({
            leaves: leavesRef.current,
            how,
            data,
        })

        setLeaves(newLeaves)
        leavesRef.current = newLeaves
        setUpdateKey(new Date().getTime())
    }

    const MyTabs = Tabs({
        data: tabs,
        activeTabIndex: tabIndex,
        returnArray: true,
        bodyClassName: "x-board-panels",
        onChange: (i, data) => {
            changeTab(i, data)
        },
    })

    return (
        <>
            <div className="x-pm-board">
                {MyTabs[0]}
                {MyTabs[1]}
            </div>
            {Boolean(!licensedPmMissions?.length) && !app.state.createMission && (
                <Bubble
                    app={app}
                    tag="x-pm-space-nav"
                    x={24}
                    y={70}
                    top={0}
                    delay={0}
                    position="fixed"
                    arrow={"top"}
                    msg={
                        <>
                            <p>
                                <b>The PM Work Space</b> <PiStackDuotone color="gold" />
                            </p>
                            <p>
                                <p>
                                    As a project or program manager, you'll see more tabs and features for managing
                                    budgets, people, timesheets, and more. Otherwise, it's a great visual launchpad for
                                    your projects.
                                </p>
                            </p>
                        </>
                    }
                />
            )}
        </>
    )
}
const getPmTimesheets = async ({ licensedPmMissions, force }) => {
    let byPass
    if (force) {
        const lf = localStorage.getItem("pm-board-ts-force")

        if (lf && new Date().getTime() - parseInt(lf) < 10000) {
            byPass = true
        } else {
            localStorage.setItem("pm-board-ts-force", new Date().getTime().toString())
        }
    }

    const lastUpdate = localStorage.getItem("pm-board-ts")
    if (byPass || (!force && lastUpdate && new Date().getTime() - parseInt(lastUpdate) < 300000)) {
        const rl = localStorage.getItem("pm-board-ts-list")

        return rl ? JSON.parse(rl) : []
    }
    let timesheets = []

    for (let m of licensedPmMissions) {
        try {
            let t = await getMissionTimesheets(m)

            if (t?.length) {
                timesheets.push(...t)
            }
        } catch (e) {}
    }

    /*try {
        debugger
        timesheets = await apiTimesheet.getProjectManagerTimesheets()
        debugger
    } catch (e) {
        timesheets = []
    }*/

    if (!timesheets?.map) {
        timesheets = []
    }

    const groupByMissionSheets = groupBy(
        timesheets?.map((ts) => ({ ...ts, missionId: getObjectId(ts.mission) })),
        "missionId"
    )

    const missionHash = makeHash(licensedPmMissions)

    let clearedTimesheets = []

    Object.keys(groupByMissionSheets).forEach((key, i) => {
        const foundMission = missionHash[key]

        if (!foundMission) {
            return
        }

        let missionSheets = groupByMissionSheets[key]

        if (foundMission.noTimesheets) {
            missionSheets = missionSheets.filter((ts) => Boolean(ts.status === "approved" || ts.status === "submitted"))
        }

        clearedTimesheets = [...clearedTimesheets, ...missionSheets]
    })

    localStorage.setItem("pm-board-ts-list", JSON.stringify(clearedTimesheets))
    localStorage.setItem("pm-board-ts", new Date().getTime().toString())

    return clearedTimesheets
}
const getPmLeaves = async ({ app, pmMissions, force }) => {
    let results = []
    let byPass

    if (force) {
        const lf = localStorage.getItem("pm-leave-force")

        if (lf && new Date().getTime() - parseInt(lf) < 30000) {
            byPass = true
        } else {
            localStorage.setItem("pm-leave-force", new Date().getTime().toString())
        }
    }

    const lastUpdate = localStorage.getItem("pm-leaves")
    try {
        if (byPass || (!force && lastUpdate && new Date().getTime() - parseInt(lastUpdate) < 10000)) {
            const rl = localStorage.getItem("pm-leaves-list")

            return rl ? JSON.parse(rl) : []
        }
    } catch (e) {}

    try {
        const d1 = startOfISOWeek(subWeeks(new Date(), 3))
        const d2 = endOfISOWeek(addWeeks(new Date(), 8))

        results = await apiPeopleLeave.getProjectManagerLeaveRequestList({
            startDate: getTimeUtc(d1),
            endDate: getTimeUtc(d2),
        })

        if (results === "[") {
            throw "error get pm leaves"
        }
    } catch (e) {
        toast.error("So sorry but I was not able to load your leaves but the team is investigating. Please stand by!")

        app.silentFail("PM leaves list issue" + e?.message)
        return []
    }

    localStorage.setItem("pm-leaves-list", JSON.stringify(results))
    localStorage.setItem("pm-leaves", new Date().getTime().toString())

    return results
}
const getPmExpenses = async ({ app, pmMissions }) => {
    let results = []

    try {
        const mHash = makeHash(pmMissions)
        results = await apiExpenses.getProjectManagerExpenses()

        results = results.filter((r) => mHash[r.mission])
    } catch (e) {
        app.silentFail("eoiwueExppps:" + formatError(e))
    }

    return results || []
}

export default Board
