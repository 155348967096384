import { isSameDay } from "date-fns/isSameDay"
import { getDailyCurrencyRateList } from "../services/currency/currency.api"
import { isAfter } from "date-fns/isAfter"
import { toZonedTime } from "date-fns-tz"

export async function getExchangeRates({ baseCurrency, currencies, date, startDate, endDate }) {
    let rates = []

    try {
        let sendObj = {}

        if (currencies) {
            sendObj.symbols = currencies.filter((c) => c !== baseCurrency)
        }

        const fetchedRates = await getDailyCurrencyRateList(baseCurrency, {
            ...sendObj,
            date,
            startDate,
            endDate,
        })

        fetchedRates?.forEach((r) => {
            let obj = {}

            rates.push({
                day: toZonedTime(r.day),
                rates: r.rates,
            })

            return obj
        })
    } catch (e) {
    } finally {
        return rates
    }
}

export function getFxForTheDay({ useMultiplier, exchangeRates, currency, day, app }) {
    if (!useMultiplier || !exchangeRates || !exchangeRates.length) return 1

    if (isAfter(day, new Date())) {
        day = new Date()
    }

    const dayRate = exchangeRates.find((r) => isSameDay(r.day, day)) || exchangeRates[exchangeRates.length - 1]

    let rateAmount

    try {
        rateAmount = dayRate?.rates[currency]
    } catch (e) {
        app?.silentFail("getFxForTheDay")
    }

    return rateAmount ? 1 - (rateAmount - 1) : 1
}
