import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import api from "../api.service"

const queryString = require("query-string")

const cleanOrgId = (id) => {
    if (typeof id === "object" && id !== null && "_id" in id) {
        return id._id // Return _id if it's a property in the object
    }
    return typeof id === "string" ? id : null // Ensure it's a string, otherwise return null
}

class TimesheetApi {
    /**
     * Submit a new Org timesheet
     *
     * @param orgId {String}
     * @param data {Object}
     * @param data.person {string} Person _id
     * @param data.weekStart {number}
     * @param data.weekEnd {number}
     * @param [data.actionItems] {string[]} Array of action item ids. Defaults to empty array
     * @param [data.hours] {number}
     * @param [data.rate] {number}
     * @param [data.rateTime] {string}
     * @param [data.billRate] {number}
     * @param [data.billUnit] {string}
     * @param [data.billTo] {string}
     * @param [data.personFullName] {string}
     * @param [options] {object}
     * @param [options.qbo] {'on'|'off'} If set to "on", it will sync to quickbooks. Defaults to "off"
     * @param [qbo] {object}
     * @param qbo.IncomeAccountRef {object} Must be an account with account type of "Sales of Product Income"
     * @param qbo.IncomeAccountRef.value {string}
     * @param [qbo.IncomeAccountRef.name] {string}
     * @param [qbo.CustomerRef] {object}
     * @param qbo.CustomerRef.value {string}
     * @param [qbo.CustomerRef.name] {string}
     * @param [qbo.DepartmentRef] {object}
     * @param qbo.DepartmentRef.value {string}
     * @param [qbo.DepartmentRef.name] {string}
     * @return {Promise<*>}
     */
    createTimesheetOrg(orgId, data, options = {}, qbo = {}) {
        const qs = queryString.stringify(options || {})
        return api
            .post(`/timesheet/org/${cleanOrgId(orgId)}?${qs}`, {
                payload: data,
                qbo,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * Update an Org timesheet
     *
     * @param orgId {String}
     * @param timesheetId {String}
     * @param data {Object}
     * @param [data.person] {string}
     * @param [data.personFullName] {string}
     * @param [data.actionItems] {string[]}
     * @param [data.hours] {number}
     * @param [data.rate] {number}
     * @param [data.rateTime] {string}
     * @param [data.billRate] {number}
     * @param [data.billUnit] {string}
     * @param [data.billTo] {string}
     * @param [data.weekStart] {number}
     * @param [data.weekEnd] {number}
     * @return {Promise<*>}
     */
    updateTimesheetOrg(orgId, timesheetId, data, notify = true) {
        return api
            .put(`/timesheet/org/${cleanOrgId(orgId)}/timesheet/${timesheetId}?notify=${notify}`, {
                payload: data,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * DELETE an Org timesheet
     *
     * @param orgId {String}
     * @param timesheetId {String}
     * @return {Promise<*>}
     */
    deleteTimesheetOrg(orgId, timesheetId) {
        return api
            .delete(`/timesheet/org/${cleanOrgId(orgId)}/timesheet/${timesheetId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * Submit a new timesheet
     *
     * @param missionId {String}
     * @param missionPlanId {String}
     * @param data {Object}
     * @param data.person {string} Person _id
     * @param data.hours {number}
     * @param data.rate {number}
     * @param data.rateTime {string}
     * @param data.weekStart {number}
     * @param data.weekEnd {number}
     * @param [data.actionItems] {string[]} Array of action item ids. Defaults to empty array
     * @param [data.personFullName] {string}
     * @param [data.billRate] {number}
     * @param [data.billTo] {string}
     * @return {Promise<*>}
     */
    createTimesheet(missionId, missionPlanId, data) {
        return api
            .post(`/timesheet/mission/${missionId}`, {
                missionPlanId,
                payload: data,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * Get timesheet list for currently logged in user.
     * @param [options] {object}
     * @param [options.weekStart] {number} Return all timesheets with a weekStart after the provided one
     * @param [options.weekEnd] {number} Return all timesheets with a weekEnd before the provided one
     * @return {Promise<*>}
     */
    getMyTimesheets(options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/timesheet?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * Get timesheet list for currently logged in user, for all missions the user is on as either admin or project manager.
     * @param [options] {object}
     * @param [options.weekStart] {number} Return all timesheets with a weekStart after the provided one
     * @param [options.weekEnd] {number} Return all timesheets with a weekEnd before the provided one
     * @return {Promise<*>}
     */
    getProjectManagerTimesheets(options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/timesheet/project-manager?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * GET mission timesheet list
     *
     * @param missionId {String}
     * @param [options] {object}
     * @param [options.weekStart] {number} Return all timesheets with a weekStart after the provided one
     * @param [options.weekEnd] {number} Return all timesheets with a weekEnd before the provided one
     * @return {Promise<*>}
     */
    getMissionTimesheet(missionId, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/timesheet/mission/${missionId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * GET org timesheet list
     *
     * @param orgId {String}
     * @param [options] {Object}
     * @param [options.clientId] {String} Optionally, filter by client
     * @param [options.weekStart] {number} Return all timesheets with a weekStart after the provided one
     * @param [options.weekEnd] {number} Return all timesheets with a weekEnd before the provided one
     * @param [options.giveOrgOnly] {boolean} Return only timesheets with "org" defined
     * @param [options.giveMissionOnly] {boolean} Return only timesheets with "mission" defined
     * @param [options.missionIds] {string[]} One or more mission ids to filter by. Applies only when "giveOrgOnly" is not true
     * @return {Promise<*>}
     */
    getOrgTimesheet(orgId, options = {}) {
        const qsOpts = {
            ...options,
            giveOrgOnly: options.giveOrgOnly === true ? "1" : "0",
            giveMissionOnly: options.giveMissionOnly === true ? "1" : "0",
        }
        const qs = queryString.stringify(qsOpts)
        return api
            .get(`/timesheet/org/${cleanOrgId(orgId)}${!isEmpty(options) ? "?" + qs : ""}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * GET mission timesheet list by person
     *
     * @param missionId {String}
     * @param personId {String}
     * @param [options] {object}
     * @param [options.weekStart] {number} Return all timesheets with a weekStart after the provided one
     * @param [options.weekEnd] {number} Return all timesheets with a weekEnd before the provided one
     * @return {Promise<*>}
     */
    getMissionTimesheetByPerson(missionId, personId, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/timesheet/mission/${missionId}/person/${personId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * Update  a timesheet
     *
     * @param missionId {String}
     * @param timesheetId {String}
     * @param data {Object}
     * @param [data.person] {string}
     * @param [data.personFullName] {string}
     * @param [data.actionItems] {string[]}
     * @param [data.hours] {number}
     * @param [data.rate] {number}
     * @param [data.rateTime] {string}
     * @param [data.billRate] {number}
     * @param [data.billTo] {string}
     * @param [data.weekStart] {number}
     * @param [data.weekEnd] {number}
     * @return {Promise<*>}
     */
    updateTimesheet(missionId, timesheetId, data, notify = true) {
        return api
            .put(`/timesheet/mission/${missionId}/timesheet/${timesheetId}?notify=${notify}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * DELETE a timesheet
     *
     * @param missionId {String}
     * @param timesheetId {String}
     * @return {Promise<*>}
     */
    deleteTimesheet(missionId, timesheetId) {
        return api
            .delete(`/timesheet/mission/${missionId}/timesheet/${timesheetId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * EXAMPLE:
     * const socket = await apiTimesheet.onChanges('update', data => console.log(data))
     * socket.disconnect()
     * @param operation {string} - update, create or delete
     * @param [query] {Object}
     * @param [cb] {Function}
     */
    onChanges(operation, query, cb) {
        if (typeof query === "function") {
            cb = query
            query = {}
        }

        let socket
        ;(async () => {
            socket = await api.socket.connect(`changes/timesheets/${operation}`, { query })
            socket.on("changes", cb)
        })()

        return {
            disconnect: () => {
                try {
                    socket?.disconnect?.()
                } catch (err) {
                    // Nothing...
                }
            },
        }
    }
}

export default new TimesheetApi()
